import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useNavigate, Link } from "react-router-dom";
import { Typography } from "@mui/material";
import swal from 'sweetalert'
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { auth, resetError } from "../../../Redux/authReducer";
import { loginUser } from "../../../Redux/authReducer";




export default function Login() {

    const navigate = useNavigate()
    const { t } = useTranslation();
    const theme = useTheme()
    const dispatch = useDispatch()
    const { isAuthenticated, status, res } = useSelector((state) => state.Auth)


    //validation Schema
    const schema = yup.object({
        password: yup.string().trim().min(5, `${t("short_password")}`).required(`${t("password_required")}`),
        email: yup.string().email(`${t("invalid_email")}`).required(`${t("email_required")}`),
    }).required();


    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    React.useEffect(()=>{
       
// console.log(res)
    },[res])


    //LOGIN USER USING AUTH CONTEXT
    const onSubmit = data => {

        dispatch(loginUser(data))




    }
    if (res.isSuccess === 0) {
        swal(
            {
                title: `Login with correct username and password`,
                // text: `${t("account_created_login")}`,
                icon: 'error',
            }
        )
        dispatch(resetError())
        
    }else if(res.user_sts){
        navigate('/')
        dispatch(resetError())
    }




    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                width: '100%', height: '100vh',
                // backgroundImage: 'url(https://source.unsplash.com/1920x720/?food)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',

            }}>


                <form onSubmit={handleSubmit(onSubmit)} >
                    <Box sx={{
                        display: 'flex', justifyContent: 'center',

                    }}>

                    </Box>

                    <Paper elevation={3} sx={{
                        display: 'flex', flexDirection: 'column', width: 345, margin: 'auto',
                        p: 5, marginTop: '5rem'
                    }}>
                        <Typography variant="h5" align="center">
                            {t('login')}
                        </Typography>

                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    error={errors.email}
                                    id="margin-dense"
                                    margin="normal"
                                    label={t("email")}
                                    defaultValue=""
                                    autocomplete="off"

                                    helperText={errors.email?.message}

                                />
                            }
                        />
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    error={errors.password}
                                    id="standard-error-helper-text"
                                    label={t("password")}
                                    margin="normal"
                                    type='password'
                                    defaultValue=""
                                    helperText={errors.password?.message}
                                />
                            }
                        />
                        <Box sx={{ fontSize: 14, alignSelf: 'end', marginTop: 1 }}>

                            <Link to="/signUp" style={{ textDecoration: 'none' }}>
                                Don't have an accuont?
                            </Link>
                        </Box>





                        <LoadingButton loading={status === 'loading'}
                            style={{ width: '6.5rem', margin: 'auto', marginTop: "1rem" }}
                            variant="contained"
                            type="submit">

                            {t('login')}
                        </LoadingButton>
                        <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

                            {/* <Link to="/register" sx={{ textDecoration: 'none' }}>
                        {t("dont_have")}
                    </Link> */}
                        </Box>
                        {/* <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

                    <Link to="/landing"  >
                        {t("back_to_home")}
                    </Link>
                </Box> */}

                    </Paper>
                </form>

            </Box>
        </ThemeProvider>

    );
}
