import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import { useProductContext } from 'context/orderListContext';
import { IconButton, Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../App.css'
import DeleteIcon from '@mui/icons-material/Delete';
// import LinearProgressBar from 'components/Progress Bar/LinearProgressBar';
// import Detail from 'components/PopUp/ShowOrderDetails/Detail';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../../Redux/cartReducer';
import { removeOrder } from '../../../Redux/orderReducer';
import CsvFile from '../CheckOut/File_Download/CsvFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomToast from '../../Components/Toast/CustomToast';
import { resetUserCreds } from '../../../Redux/orderReducer';

export default function ExitingOrders() {
    const [page, setPage] = React.useState(0);
    // const { filterdPro, setStatus, status, loading, loadingPro } = useFilterContext()
    const orderList = useSelector((state) => state.OrderListReducer.orderList)

    const [rowsPerPage, setRowsPerPage] = React.useState(40);

    // const { setCurrentItem } = useCartContext()
    const { t } = useTranslation()
    const dispatch = useDispatch()

const ref=React.useRef(null)
const handleToast=()=>{
    ref.current.click()
}


dispatch(resetUserCreds())

    // console.log(typeof filterdPro)
    //Creat data for row
    function createData(serial, book_id, isbn, author, title, publisher, currency, price, required_qnt, vol_set, edition) {
        return { serial, book_id, isbn, author, title, publisher, currency, price, required_qnt, vol_set, edition };
    }


    const rows =
        orderList.map((item, i) => {

            return createData(i, item.book_id, item.isbn, item.author, item.title, item.publisher, item.currency, item.price, item.required_qnt, item.vol_set, item.edition)

        })


    return (
        <>

            <Box id='scroll' sx={{ p: 4 }}>
                <Typography variant='h5' color='error' align='right' sx={{ display: `${orderList.length < 1 && 'none'}` }}>
                    {/* {t('no_item')} */}
                    <CsvFile />
                </Typography>
                <TableContainer  >


                    {/* <Table stickyHeader aria-label="sticky table" sx={{ display: `${status === null ? 'none' : ''}`, marginTop: 5 }}> */}
                    <Table stickyHeader aria-label="sticky table" sx={{ marginTop: 1, display: `${orderList.length < 1 && 'none'}` }}>
                        <TableHead >
                            <TableRow >

                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} ># </TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center" >ISBN </TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center" >Author</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">Title</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black', width: '10rem' }} align="center">Publisher</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">Currency</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">Price</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black', width: '7rem' }} align="center">Order Qnt</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">Edition</TableCell>
                                {/* <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">Print</TableCell> */}
                                <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">Order</TableCell>



                            </TableRow>
                        </TableHead>
                        <TableBody

                        >
                            {

                                rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >


                                                <TableCell align="center">{row.serial + 1}</TableCell>
                                                <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 15 }}>

                                                    {row.isbn}
                                                </TableCell>
                                                <TableCell align="center">{row.author}</TableCell>
                                                <TableCell align="center">{row.title}</TableCell>
                                                <TableCell align="center">{row.publisher}</TableCell>
                                                <TableCell align="center">{row.currency}</TableCell>



                                                <TableCell align="center">{row.price}</TableCell>

                                                <TableCell align="center" >{row.required_qnt}
                                                </TableCell>

                                                {/* DETAILS TABLE CELL */}

                                                <TableCell align="center">
                                                    {row.edition}
                                                </TableCell>

                                                {/* CART LOGO TABLE CELL */}
                                                <TableCell align="center">
                                                    <Box sx={{display:'flex'}}>

                                                    <Button variant="contained"
                                                        // sx={{ width: '8rem' }}
                                                        onClick={() => {
                                                            handleToast()
                                                            dispatch(addToCart({ item: row, qnt: row.required_qnt, newbook: true }))

                                                        }
                                                        }
                                                    >
                                                        {/* Add to cart */}
                                                        <AddShoppingCartIcon  />
                                                    </Button>
                                                    <Button variant="contained"
                                                        color='error'
                                                        sx={{ ml:1 }}
                                                        onClick={() => {
                                                            dispatch(removeOrder({ isbn: row.isbn }))

                                                        }
                                                        }
                                                    >
                                                        {/* Remove */}
                                                        <DeleteForeverIcon  />
                                                    </Button>
                                                        </Box>
                                                </TableCell>


                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>

                    <Box sx={{ display: `${!orderList.length < 1 ? 'none' : "flex"}`, flexDirection: 'column', mt: 20, justifyContent: 'center', alignItems: 'center' }}>

                        <Typography align='center'>
                            No Order Added
                        </Typography>
                        {/* <Button
                            onClick={() => navigate('/order')}
                            variant='contained' sx={{ width: '10rem', mt: 3 }}>
                            Add New Book
                        </Button> */}
                    </Box>
                </TableContainer>
                {/* <TablePagination
                    sx={{ position: 'absolute', bottom: 0, right: 0, display: `${orderList.length < 1 && 'none'}` }}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                <CustomToast ref={ref} message={'Added to Cart'} />
            </Box></>
    );
}
