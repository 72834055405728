import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from "react-i18next";
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate,useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
export default function BottomTabs() {
    const { t } = useTranslation()
    const location=useLocation()
    const [value, setValue] = React.useState(location.pathname);
    const navigate=useNavigate()
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    React.useEffect(()=>{
        // setValue(location.pathname.trim().replace(/^\/+|\/+$/g, '')===''?'home':location.pathname.trim().replace(/^\/+|\/+$/g, ''))
        // console.log( location.pathname.trim().replace(/^\/+|\/+$/g, ''))
        setValue(location.pathname)
    },[location.pathname])
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },zIndex:999}} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction 
          onClick={()=>navigate('/')}
          label={t('home')} 
          value="/" 
          icon={<MenuBookIcon />} 
        />
        <BottomNavigationAction 
          onClick={()=>navigate('/order')}
          label={'Add New'} 
          value="/order" 
          icon={<AddCircleIcon />} 
          selected={value === '/order' || value === '/checkout' || value === '/payment'}
        />
        {/* <BottomNavigationAction 
          onClick={()=>navigate('/completedOrders')}
          label={t('completedOrders')} 
          value="/completedOrders" 
          icon={<AddTaskIcon />} 
          selected={value === '/completedOrders'}
        /> */}
        <BottomNavigationAction 
          onClick={()=>navigate('/cart')}
          label={t('cart')} 
          value="/cart" 
          icon={<ShoppingCartIcon />} 
          selected={value === '/cart'}
        />
      </BottomNavigation>
    </Paper>
    );
}