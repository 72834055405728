import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cartItems: [],
    orderDetails: ''
}

export const cartSlice = createSlice({
    name: 'cartReducer',
    initialState,
    reducers: {
        //ADD TO CART
        // addToCart: (item, size, state) => {
        addToCart: (state, action) => {
            // console.log(action.payload)
            const exist = state.cartItems.find((elem) => elem.book_id === action.payload.item.book_id);
            if (exist) {
                // state.cartItems = state.cartItems.map((x) => x.book_id === action.payload.item.book_id ? { ...exist, qnt: +exist.qnt + +action.payload.qnt } : x);
                // localStorage.setItem("cartItems", JSON.stringify([...state.cartItems, action.payload.item]));
                
            } else {
                let product = { ...action.payload.item, qnt: action.payload.qnt,newbook:action.payload.newbook };
                state.cartItems = [...state.cartItems, product];
                localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            }
        },


        //REMOVE FORM CART
        removeFromCart: (state, action) => {
            // console.log(action)
            state.cartItems =
                state.cartItems.filter((x) => x.book_id !== action.payload.item.book_id
                    // state.cartItems.filter((x) => x.book_id !== action
                )
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems.filter((x) => x.book_id !== action.payload.item.book_id)))

        },

        //INCRIMENT IN QUANTITY
        incrementQnt: (state, action) => {
            // if (+item.product_quantaty < 10)
            // console.log(action.payload)
            state.cartItems = state.cartItems.map((x) => x.book_id === action.payload.item.book_id ? { ...action.payload.item, qnt: +action.payload.item.qnt + 1 } : x)

            localStorage.setItem("cartItems", JSON.stringify(state.cartItems.map((x) => x.book_id === action.payload.item.book_id ? { ...action.payload.item, product_quantaty: +action.payload.item.product_quantaty + 1 } : x)))
        },
        //DECRIMENT IN QUANTITY
        decrementQnt: (state, action) => {
            if (action.payload.item.qnt > 1)
                state.cartItems = state.cartItems.map((x) => x.book_id === action.payload.item.book_id ? { ...action.payload.item, qnt: +action.payload.item.qnt - 1 } : x)

            localStorage.setItem("cartItems", JSON.stringify(state.cartItems.map((x) => x.book_id === action.payload.item.book_id ? { ...action.payload.item, product_quantaty: String(+action.payload.item.product_quantaty - 1) } : x)))
        },
        incrementQntByAmount: (state, action) => {
            state.cartItems += action.payload.count
        },
        setOrderDetails: (state, action) => {
            state.orderDetails = action.payload
        },
        getCartItems: (state, action) => {
            // state.cartItems = action.payload
            let cartItems = JSON.parse(localStorage.getItem('cartItems'))
            if (cartItems != null)
                state.cartItems = cartItems
            // console.log(JSON.parse(localStorage.getItem('cartItems')))
        },
        resetCart: (state) => {
            // state.cartItems = initialState.cartItems
            state.cartItems = initialState.cartItems
            localStorage.removeItem('cartItems')
        }
    },
})

// Action creators are generated for each case reducer function
export const { incrementQnt, decrementQnt, addToCart, removeFromCart, resetCart, setOrderDetails, getCartItems } = cartSlice.actions

export default cartSlice.reducer