import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgressWithLabel from '../Loader/CircularProgressWithLabel'
import { useSelector } from 'react-redux';
const LoaderToast = React.forwardRef(({ message }, ref) => {
    const [open, setOpen] = React.useState(true);
    const { load } = useSelector((state) => state.PorductReducer)


    React.useEffect(() => {
        if (load === 2)
            setOpen(true);

    }, [load]);
    React.useEffect(() => {
        if (load === 4)
            setOpen(false)
    }, [load])



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose} sx={{ display: 'none' }}>
                UNDO
            </Button>
            {/* <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton> */}
            <CircularProgressWithLabel />
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                // autoHideDuration={2000}
                // onClose={handleClose}
                message={'Loading...'}

                action={action}
            />
        </div>
    );
})
export default LoaderToast;
