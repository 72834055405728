import * as React from 'react';

import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';


 function CoursesSelector({filterState, setFilterState,reset}) {
  const { products } = useSelector(state => state.PorductReducer)


  const uniqueCourse =React.useMemo(()=>{
    return  products ? products.reduce((acc, product) => {
      if (!acc.includes(product.course)) {
        acc.push(product.course);
      }
      return acc;
    }, []) : [];
  },[products])
 

  const [value, setValue] = React.useState(uniqueCourse[0]);
  const [inputValue, setInputValue] = React.useState('');

React.useEffect(()=>{
  setValue([])
  setInputValue('')
},[reset])
  return (
    <FormControl sx={{ width: '100%', marginLeft: 1 }}>
 
    <MemoizedAutocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            setFilterState({...filterState,courses:newValue})
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="publisher"
          options={uniqueCourse}
          size='small'
          getOptionLabel={(option) => typeof option === 'string' ? option : ''}
          isOptionEqualToValue={(option, value) => option === value}

          // sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Course" />}
        />
    </FormControl>
  );
}

const MemoizedAutocomplete = React.memo(Autocomplete);

export default CoursesSelector;
