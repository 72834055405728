import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from "react-router-dom";
// import { useUserAuth } from "context/UserAuthContext";
import { Grid, Typography } from "@mui/material";
import swal from 'sweetalert'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';






export default function ForgetPassword({ setScreen }) {
  // const navigate = useNavigate()
  const { t } = useTranslation();
  // const { forgetPassword, passStatus, setPassStatus, loading,setForgetEmail } = useUserAuth()



  //validation Schema
  const schema = yup.object({
    email: yup.string().email(`${t("invalid_email")}`).required(`${t("email_required")}`),
  }).required();


  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });



  // if (passStatus.is_sts === 0) {

  //   swal(
  //     {
  //       title: `${t("error")}`,
  //       text: `${passStatus.msg}`,
  //       icon: 'error',
  //     }
  //   )
  //   setPassStatus('')

  // }
  // if (passStatus.is_sts === 1) {

  //   swal(
  //     {
  //       title: `${t("success")}`,
  //       text: `${t("password_send_to_email")}`,
  //       icon: 'success',
  //       buttons: false,
  //       timer: 1000
  //     }
  //   )
  //   setScreen(1)
  //   setPassStatus('')
  // }

  const onSubmit = data => {

    // forgetPassword(data)
    // setForgetEmail(data.email)

    // console.log(data)

  }



  return (
    <form onSubmit={handleSubmit(onSubmit)} >
   

      <Paper elevation={3} sx={{
        display: 'flex', flexDirection: 'column', width: 345, margin: 'auto',
        p: 5
      }}>
        <Typography variant="h5" align="center">
          {t('forgot_password')}
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) =>
            <TextField
              {...field}
              error={errors.email}
              id="margin-dense"
              margin="normal"
              label={t("email")}
              defaultValue=""
              autocomplete="off"

              helperText={errors.email?.message}

            />
          }
        />


        <LoadingButton 
        // loading={loading}
          style={{ width: '6.5rem', margin: 'auto', marginTop: "1rem" }}
          variant="contained"
          type="submit"
          // onClick={() => forgetPassword()}
        >

          {t('send')}
        </LoadingButton>



        <Box sx={{ display: 'flex', fontSize: 16, justifyContent: 'center', marginTop: 5 }}>

          <Link to="/login"  >
            {t("login")}
          </Link>
        </Box>

        {/* <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

          <Link to="/"  >
            {t("back_to_home")}
          </Link>
        </Box> */}

      </Paper>
    </form>

  );
}
