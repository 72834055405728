import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// console.log(process.env.REACT_APP_BASE_URL)


//  export const loginUser = createAsyncThunk("loginUser", async () => {
//     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/hotel/api/pro_api.php`);
//     // return response.json();
//     console.log(response.data)
// });

export const loginUser = createAsyncThunk(
    'loginUser',
    async (params, thunkAPI) => {
        // console.log(params)
        try {
            // const response = await axios.get(`https://link.inposizione.it/admin/api/login.php?type=logininfo&username=${user.email}&password=${user.password}&role=articolisti`, {
            const response = await axios.get(`${process.env.REACT_APP_URL}/login.php?type=logininfo&username=${params.email}&password=${params.password}`);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const registerUser = createAsyncThunk(
    'registerUser',
    async (params, thunkAPI) => {
        // console.log(params)
        // const phoneNumber = params.phoneNo.replace(/\s|\+/g, "").trim();
        try {
            // &username=${params.name}&email=${params.email}&password=${params.password}&password=${params.phoneNo}
            const response = await axios.get(`${process.env.REACT_APP_URL}/api.php?type=registration&username=${params.name}&email=${params.email}&password=${params.password}&phone=${params.phoneNo}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const addFilter = createAction('Filter')

const initialState = {
    user: '',
    status: 'idle',
    error: null,
    isAuthenticated: false,
    res: {}

}
// 252580

const authSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // filterProducts:
        //   (state, action) => {
        //     if (action.payload === 'All') {
        //       state.filteredPro = state.products
        //     }
        //     else {
        //       state.filteredPro = state.products.filter(item => item.categories_name === action.payload)
        //     }


        //   }
      
        checkUser: (state, action) => {
            // console.log('helo')
            const user = JSON.parse(localStorage.getItem('user'))
            // console.log(user.user_sts)
            if (user != null) {
                if (user.user_sts) {

                    state.isAuthenticated = true
                    state.res = user
                }
            }

        },

        resetError:
            (state, action) => {
                state.res = {}
            },
        handleLogout:
            (state, action) => {
                state.res = {}
                localStorage.clear()
                localStorage.removeItem('cartItems')
                state.isAuthenticated = false

            },

    },
    extraReducers: {
        [loginUser.pending]: (state, action) => {
            state.status = 'loading';
        },
        [loginUser.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.res = action.payload;
            // console.log(action.payload.user_sts)
            if (action.payload.isSuccess === 0) {
                state.error = action.payload.msg;

            } else {
                // console.log('helo')
                state.isAuthenticated = true;
                localStorage.setItem('user', JSON.stringify(action.payload));
            }

        },
        [loginUser.rejected]: (state, action) => {
            state.status = 'failed';
            // console.log(action.payload)
            state.error = action.payload;
        },
        

        

        [registerUser.pending]: (state, action) => {
            state.status = 'loading';
        },
        [registerUser.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.res = action.payload;
            // console.log(action.payload)
            if (action.payload.is_sts === 0) {
                state.error = action.payload;

            } else {
                // console.log('helo')
                state.isAuthenticated = true;
                localStorage.setItem('user', JSON.stringify(action.payload));
            }

        },
        [registerUser.rejected]: (state, action) => {
            state.status = 'failed';
            // console.log(action.payload)
            state.error = action.payload;
        }
    }
});

export const { resetError, checkUser, handleLogout } = authSlice.actions
export default authSlice.reducer

