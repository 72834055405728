import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import Brach from '../../Components/Seloctors/Brach'
import CategorySelector from '../../Components/Seloctors/CategorySelector'
import Courses from '../../Components/Seloctors/Courses'
import LevelSelector from '../../Components/Seloctors/LevelSelector'
import Subject from '../../Components/Seloctors/Subject'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { resetFilter } from '../../../Redux/productReducer'
import { LoadingButton } from '@mui/lab'
import { filterBooks } from '../../../Redux/productReducer'
// import AuthorSelector from '../../Components/Seloctors/AuthorSelector'
// import PublisherSelector from '../../Components/Seloctors/PublisherAuther'

export default function FilterDiv() {

    const dispatch = useDispatch()
    const [cancelFilter, setCancelFilter] = React.useState(false)
    const [reset, setReset] = React.useState(false)
    const { filterSts } = useSelector((state) => state.PorductReducer)

    // const {status}=useSelector(state=>state.ProductReducer)
    const [filterState, setFilterState] = React.useState({
        subject: '',
        level_board: '',
        category: '',
        courses: '',
        branch: '',
        publisher: ''
    })
    return (
        <Box sx={{marginLeft:-1,marginRight:1}}>

            {/* <Typography sx={{ mb: 2 }} align='center' variant='h6'>
                Filter
            </Typography> */}
            <Grid container alignContent='center' spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <CategorySelector filterState={filterState} setFilterState={setFilterState} reset={reset} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Subject filterState={filterState} setFilterState={setFilterState} reset={reset} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Brach filterState={filterState} setFilterState={setFilterState} reset={reset} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Courses filterState={filterState} setFilterState={setFilterState} reset={reset} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <LevelSelector filterState={filterState} setFilterState={setFilterState} reset={reset} />
                </Grid>
                {/* <Grid item xs={12} sm={4} md={4} lg={4}>

                    <AuthorSelector filterState={filterState} setFilterState={setFilterState} reset={reset}/>
                </Grid> */}
                {/* <Grid item xs={12} sm={4} md={4} lg={4}>

                    <PublisherSelector filterState={filterState} setFilterState={setFilterState} reset={reset}/>
                </Grid> */}
                <Grid item xs={12} sm={4} md={4} lg={4} >
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>

                        <LoadingButton
                            loading={filterSts === 'loading'}
                            onClick={() => {
                                // dispatch(applyFilter(filterState))}}
                                setCancelFilter(true)
                                dispatch(filterBooks(filterState))
                            }}
                            variant='contained' >
                            Apply Filter
                        </LoadingButton>
                        <Button variant='contained'
                            disabled={!cancelFilter}
                            onClick={() => {
                                setCancelFilter(!cancelFilter)
                                setReset(!reset)
                                dispatch(resetFilter())
                                setFilterState({
                                    subject: '',
                                    level_board: '',
                                    category: '',
                                    courses: '',
                                    branch: ''
                                })
                            }}
                            color='error'>

                            Clear Filter
                        </Button>
                    </Box>
                </Grid>

            </Grid>

        </Box>
    )
}
