import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Grid } from '@mui/material';


import { Box } from '@mui/material';
import '../../../App.css'
import { useNavigate } from 'react-router-dom';
import { addToCart } from '../../../Redux/cartReducer';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import QuantityInput from '../Input/QuantityInput';
import CustomToast from '../Toast/CustomToast';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DownloadIcon from '@mui/icons-material/Download';
import { removeFromCart } from '../../../Redux/cartReducer';
import * as XLSX from 'xlsx';
import '../../../App.css'
import { set } from 'lodash';



// CELL HEADER
const headCells = [
    {
        id: 'serail',
        numeric: false,
        disablePadding: true,
        label: '#',
    },
    {
        id: 'isbn',
        numeric: true,
        disablePadding: false,
        label: 'ISBN',
    },
    {
        id: 'author',
        numeric: true,
        disablePadding: false,
        label: 'Author',
        renderHeader: () => (
            <div>
                <input type="text" placeholder="Filter by name" />
            </div>
        ),
    },
    {
        id: 'title',
        numeric: true,
        disablePadding: false,
        label: 'Title',
    },
    {
        id: 'Publisher',
        numeric: true,
        disablePadding: false,
        label: 'Publisher',
    },
    {
        id: 'currency',
        numeric: true,
        disablePadding: false,
        label: 'Currency',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'qty',
        numeric: true,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'vol_set',
        numeric: true,
        disablePadding: false,
        label: 'Vol_Set',
    },
    {
        id: 'edition',
        numeric: true,
        disablePadding: false,
        label: 'Edition',
    },
    {
        id: 'order',
        numeric: true,
        disablePadding: false,
        label: 'Order',
    },
];



export default function DataTable() {
    // // const [order, setOrder] = React.useState('asc');
    // const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const products = useSelector((state) => state.PorductReducer.products)
    const filteredPro = useSelector((state) => state.PorductReducer.filteredPro)
    const cartItems = useSelector((state) => state.CartReducer.cartItems)
    const [search, setSearch] = React.useState(filteredPro)
    const [isbn, setIsbn] = React.useState('')
    // const [universalSearch,setUniversalSearch]=React.useState(filteredPro)
    const [qnt, setQnt] = React.useState(1)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [author, setAuthor] = React.useState('')
    const [srch, setSrch] = React.useState('')
    const [publisher, setPublisher] = React.useState('')
    const toastRef = React.useRef(null)
    const [activeFilter,setActiveFilter]=React.useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const { setCurrentItem } = useCartContext()
    // const { t } = useTranslation()

    //   const [dense, setDense] = React.useState(false);
    //   const [rowsPerPage, setRowsPerPage] = React.useState(5);



    const filteredItems = products.filter(item => selected.includes(item.book_id))
    //   console.log(filteredItems)
    const headers = ['ISBN', 'Author', 'Title', 'Publisher', 'Currency', 'Price', 'Qty', 'Edition',];
    const data = filteredItems.map(item => [item.isbn, item.author, item.title, item.publisher, item.currency, item.price, item.order_qty, item.edition]);
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cart Items');

    function downloadExcelFile() {
        XLSX.writeFile(workbook, 'amit_bookDepot.xls');
    }


    function EnhancedTableHead(props) {
        const { onSelectAllClick, numSelected, rowCount } =
            //   const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        //   const createSortHandler = (property) => (event) => {
        //     onRequestSort(event, property);
        //   };




        // React.useEffect(()=>{
        //     console.log(products)
        // },[products])


        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        {/* <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        /> */}
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            sx={{ fontWeight: "bold", fontSize: 16, textAlign: 'center', display: `${search.length < 1 && 'none'}` }}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                            {/* {headCell.id === 'author' && (
                                <TextField
                                    size='small'
                                    sx={{ width: '8rem' }}
                                    // label="Search Author"
                                    value={author}
                                    onChange={(e) => handleAuthor(e.target.value)}
                                />
                            )}
                            {headCell.id === 'Publisher' && (
                                <TextField
                                    size='small'
                                    sx={{ width: '8rem' }}
                                    // label="Search Publisher"
                                    value={publisher}
                                    onChange={(e) => handlePublisher(e.target.value)}
                                />
                            )} */}
                        </TableCell>
                    ))}

                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        //   onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        //   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        //   orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;


        // HANDLE SEARCH INPUT


        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >

                {/* TOOLBAR TO BE EDIT */}
                {numSelected > 0 ? (
                    <Typography
                        // sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                    </Typography>

                )}

                {/* //ACTION ICONS */}
                {numSelected > 0 ? (
                    <Tooltip title="Download xls file">
                        <Button
                            variant='contained'
                            endIcon={<DownloadIcon />}
                            onClick={() => {
                                // console.log(selected)
                                downloadExcelFile()
                            }} >
                            Download selected
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            {/* <FilterListIcon /> */}
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };




    React.useEffect(() => {
        setSearch(
            filteredPro.map(product => ({
                ...product,
                inCart: cartItems.some(item => item.book_id === product.book_id)
            }))

        )
        setPage(0)
    }, [filteredPro])


    const handleToast = () => {
        toastRef.current.click()
    }

    //Creat data for row
    function createData(serial, book_id, isbn, author, title, publisher, currency, price, order_qty, vol_set, edition, inCart) {
        return { serial, book_id, isbn, author, title, publisher, currency, price, order_qty, vol_set, edition, inCart };
    }


    const rows =
        search.map((item, i) => {

            return createData(i, item.book_id, item.isbn, item.author, item.title, item.publisher, item.currency, item.price, item.order_qty, item.vol_set, item.edition, item.inCart)

        })





    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.book_id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row.book_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.book_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        // console.log(newSelected)
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const isSelected = (row) => selected.indexOf(row) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;






    const handleSearch = (searchText, author, publisher, isbn) => {
        setActiveFilter(true)
        setPage(0)
        const filteredData = filteredPro.filter(item => {
            const itemTitle = item.title ? item.title.toUpperCase() : '';
            const itemAuthor = item.author ? item.author.toUpperCase() : '';
            const itemPublisher = item.publisher ? item.publisher.toUpperCase() : '';
            const itemIsbn = item.isbn ? item.isbn.toUpperCase() : '';
            const textData = searchText.toUpperCase();

            const matchesSearchText = itemTitle.indexOf(textData) > -1 ||
                itemIsbn.indexOf(textData) > -1 ||
                itemPublisher.indexOf(textData) > -1 ||
                itemAuthor.indexOf(textData) > -1;

            const matchesAuthor = !author || itemAuthor.indexOf(author.toUpperCase()) > -1;
            const matchesPublisher = !publisher || itemPublisher.indexOf(publisher.toUpperCase()) > -1;
            const matchesIsbn = !isbn || itemIsbn.indexOf(isbn.toUpperCase()) > -1;

            return matchesSearchText && matchesAuthor && matchesPublisher && matchesIsbn;
        });

        setSearch(filteredData);

    };

    const handleIsbn = (text) => {
        handleSearch('', '', '', text);
    };




    const handleFilter = (text) => {
        // console.log(text)
        setActiveFilter(true)
        setPage(0)
        if (text) {
            const newData = filteredPro.filter(item => {
                const itemTitle = item.title ? item.title.toUpperCase() : '';
                const itemISBN = item.isbn ? item.isbn.toUpperCase() : '';
                const itemPublisher = item.publisher ? item.publisher.toUpperCase() : '';
                const itemAuthor = item.author ? item.author.toUpperCase() : '';
                const textData = text.toUpperCase();
                return (
                    itemTitle.indexOf(textData) > -1 ||
                    itemISBN.indexOf(textData) > -1 ||
                    itemPublisher.indexOf(textData) > -1 ||
                    itemAuthor.indexOf(textData) > -1
                );
            });
            setSearch(newData);
        } else {
            setSearch(filteredPro);
        }

    }
    const handleResetFilter=()=>{
        setSearch(filteredPro)
        setIsbn('')
        setPublisher('')
        setAuthor('')
        setSrch('')
        setActiveFilter(false)
    }




    return (
        <Box sx={{ width: '100%' }}>
            <Box>



                <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField id="standard-basic"

                            placeholder='Search ISBN,Title,Author,Publisher '
                            // sx={{ width: '100%' }}
                            value={srch}
                            fullWidth
                            size='small'
                            // onChange={(e) => console.log(e.target.value)}
                            onChange={(e) => {
                                setSrch(e.target.value)
                                // handleSearch(e.target.value, author, publisher)
                                handleFilter(e.target.value)

                            }}
                        // variant="outline"

                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField

                            placeholder='Search Author '
                            size='small'
                            fullWidth
                            value={author}
                            onChange={(e) => {
                                // handleAuthor(e.target.value)
                                setAuthor(e.target.value)
                                handleSearch(srch, e.target.value, publisher, isbn)

                            }}
                        // variant="outline"

                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextField

                            placeholder='Search Publisher '
                            fullWidth
                            value={publisher}
                            size='small'
                            onChange={(e) => {
                                // handlePublisher(e.target.value)
                                setPublisher(e.target.value)
                                handleSearch(srch, author, e.target.value, isbn)

                            }}
                        // variant="outline"

                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Box sx={{display:'flex'}}>

                            <TextField

                                placeholder='Search ISBN '
                                fullWidth
                                value={isbn}
                                size='small'
                                onChange={(e) => {
                                    setIsbn(e.target.value)
                                    // handleIsbn(e.target.value)
                                    handleSearch(srch, author, publisher, e.target.value)

                                }}
                            // variant="outline"

                            />
                            <Button 
                            onClick={()=>handleResetFilter()}
                            sx={{ml:1,display:`${!activeFilter&&'none'}`}}  variant='contained' >Clear</Button>
                        </Box>
                    </Grid>

                </Grid>



                <EnhancedTableToolbar

                    numSelected={selected.length} />
                <TableContainer sx={{ maxHeight: 490 }} id='scroll'>

                    <Table

                        stickyHeader
                    // style={{marginBottom:5}} 
                    // size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            key={rows.book_id}


                            //   order={order}
                            //   orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            //   onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.book_id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.book_id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.book_id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, row)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.serial + 1}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 15 }}>

                                                <a
                                                    style={{ textDecoration: 'none' }}
                                                    target={'blank'} href={`https://www.amazon.in/s?k=${row.isbn}&ref=nb_sb_noss`}>{row.isbn}</a>

                                            </TableCell>
                                            <TableCell align="left">{row.author}</TableCell>
                                            <TableCell align="left">{row.title}</TableCell>
                                            <TableCell align="left">{row.publisher}</TableCell>
                                            <TableCell align="center">{row.currency}</TableCell>
                                            <TableCell align="center">{row.price}</TableCell>



                                            <TableCell align="center" sx={{ color: 'primary' }}>

                                                <QuantityInput row={row} setQnt={setQnt} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: 'primary' }}>{row.vol_set}</TableCell>
                                            {/* <TableCell align="center">{row.edition}</TableCell> */}


                                            {/* DETAILS TABLE CELL */}

                                            <TableCell align="center">
                                                {row.edition}
                                            </TableCell>

                                            {/* CART LOGO TABLE CELL */}
                                            <TableCell align="center">{row.inCart ?

                                                <Button
                                                    variant='contained'
                                                    color='error'
                                                    onClick={() => {
                                                        dispatch(removeFromCart({ item: row }))
                                                        setSearch(search.map(item => item.book_id === row.book_id ? { ...item, inCart: false } : item));

                                                    }}
                                                >
                                                    <DeleteForeverIcon />

                                                </Button>
                                                : <Button variant="contained"
                                                    // sx={{ width: "8rem" }}
                                                    onClick={() => {
                                                        handleToast()
                                                        dispatch(addToCart({ item: row, qnt: qnt }))
                                                        // handleCartItems()
                                                        setQnt(1)
                                                        setSearch(search.map(item => item.book_id === row.book_id ? { ...item, inCart: true } : item));

                                                    }
                                                    }

                                                >
                                                    <AddShoppingCartIcon />
                                                </Button>
                                            }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <Box sx={{ display: `${!search.length < 1 ? 'none' : "flex"}`, flexDirection: 'column', mt: 5, justifyContent: 'center', alignItems: 'center' }}>

                        <Typography align='center'>
                            No Item Found
                        </Typography>
                        <Button
                            onClick={() => navigate('/order')}
                            variant='contained' sx={{ width: '10rem', mt: 3 }}>
                            Add New Book
                        </Button>
                    </Box>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    sx={{ display: `${search.length < 1 ? 'none' : "block"}` }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
            <CustomToast ref={toastRef} message={'Added to Cart'} />
        </Box>
    );
}