import React from 'react';
import loaderGif from '../../../Ascets/animaiton.gif';
// import './Custom.css'

const CustomLoader = () => {
    return (
        <div style={{width:'100%'}}>

      <div
        style={{
            backgroundImage: `url(${loaderGif})`,
            backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            height: '50vh',
            // width: '100vw',
            animationName: 'spin',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
        }}
        />
        </div>
    );
  };

export default CustomLoader;
