import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
// import LogoIcon from '../../assets/svg/Inposizione-Logo.png'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { resetError } from "../../../Redux/authReducer";
import { registerUser } from "../../../Redux/authReducer";



const theme = createTheme();

export default function SignUp() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    //Yup Schema
    const schema = yup.object({
        password: yup.string().trim().min(5, `${t("short_password")}`).required(`${t("password_required")}`),
        confirmPassword: yup.string().trim().oneOf([yup.ref('password'), null], 'Password dsn`t match'),
        email: yup.string().email(`${t("invalid_email")}`).required('Email Required'),
        name: yup.string().trim().required("Name required"),
        // country: yup.string().trim().required(`${t("country_required")}`),
        // city: yup.string().trim().required(`${t("city_required")}`),
        phoneNo: yup.string().min(11, "Too short phone no").required("Phone no required")
    }).required();

    const { status, res,error } = useSelector((state) => state.Auth)

    const [gender, setGender] = React.useState('');
    // const { registerUser, loading, resStatus, setResStatus } = useUserAuth()
    const navigate = useNavigate()
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        dispatch(registerUser(data))

        // registerUser({ ...data })
        // console.log(data)


    }

    if (res.is_sts === 0) {
        swal(
            {
                title: `Error`,
                text: error.msg,
                icon: 'error',
            }
        )
        // console.log(error)
        dispatch(resetError())

    } else if (res.is_sts === 1) {
        swal(
            {
                title: `Success`,
                text: `User created successfully`,
                icon: 'success',
                timer: 1000,
                buttons: false
            })
        navigate('/login')
        dispatch(resetError())
    }



    const handleChange = (event) => {
        setGender(event.target.value);
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />

                <Paper elevation={3}
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5
                        , mt: '2rem',
                        // height:'10rem'
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography variant="h5" align="center" gutterBottom>
                        SignUp
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="name"
                                            // required
                                            fullWidth
                                            id="name"
                                            label="Name"
                                            defaultValue=""
                                            error={errors.name}
                                            helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="email"
                                            label={t("email")}
                                            name="email"
                                            autoComplete="email"
                                            defaultValue=""
                                            error={errors.email}
                                            helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}  lg={6} xl={6}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="country"
                                            label="Country"
                                            name={t("country")}
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.country}
                                            helperText={errors.country?.message}
                                        />} />
                            </Grid> */}

                            {/* 
                            <Grid item xs={12} sm={6}  lg={6} xl={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="city"
                                            label={t("city")}
                                            name="city"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.city}
                                            helperText={errors.city?.message}
                                        />}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="password"
                                            label={t("password")}
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.password}
                                            helperText={errors.password?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="confirmPassword"
                                            label={t("confirmPassword")}
                                            type="password"
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.confirmPassword}
                                            helperText={errors.confirmPassword?.message}
                                        />}
                                />
                            </Grid>

                            {/* 
                            <Grid item xs={12} sm={4} lg={4} xl={4}>
                                        

                                <FormControl sx={{minWidth:{sm:100,xs:300}}}>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={gender}
                                        // fullWidth
                                        label={t('gender')}
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={'male'}>{t("male")}</MenuItem>
                                        <MenuItem value={'female'}>{t("female")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="phoneNo"
                                    control={control}
                                    sx={{ width: 200 }}
                                    render={({ field: { onChange, value } }) =>
                                        <MuiPhoneNumber defaultCountry={'in'}
                                            sx={{ marginTop: 3, width: "100%" }}
                                            // required
                                            defaultValue=""
                                            onChange={onChange} value={value}
                                            error={errors.phoneNo}
                                            helperText={errors.phoneNo?.message}
                                            options={{
                                                excludeCountries: [''],
                                                disableAreaCodes: false

                                            }}

                                        />} />
                            </Grid>
                        </Grid>
                        <LoadingButton
                            loading={status === 'loading'}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            {t("signUp")}
                        </LoadingButton>

                        <Box sx={{ fontSize: 14, display: 'flex', justifyContent: 'center', mt: 2 }}>

                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                Already have an account?
                            </Link>
                        </Box>
                        {/* <Box sx={{ fontSize: 14, textAlign: 'center', marginTop: 4 }}>

                            <Link to="/landing"  >
                                {t("back_to_home")}
                            </Link>
                        </Box> */}
                    </form>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}

