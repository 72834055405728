import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CartCheckout from '../../Screens/CheckOut/CartCheckout';
import CredentialFrom from '../../Screens/CheckOut/CredentialForm';
import { Paper } from '@mui/material';
import Swal from 'sweetalert2';

// import CSV from '../../Screens/CheckOut/File_Download/Csv';
// import PdfFile from '../../Screens/CheckOut/File_Download/PdfFile';
import FileDownload from '../../Screens/CheckOut/File_Download/FileDownload';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetCart } from '../../../Redux/cartReducer';
import { resetUserCreds } from '../../../Redux/orderReducer';
const steps = ['Checkout Cart', 'Credentials', 'All Done'];

export default function CustomStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [formSubmited, setFormSubmited] = React.useState(false)
    const buttonRef = React.useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const handleClick = () => {
        buttonRef.current.click()
    }

    const handleRestCart = () => {
        handleReset()
        dispatch(resetCart())
        // navigate('/')
    }



    const modalTrigger = () => {
        Swal.fire({
            title: 'AMIT BOOK DEPOT',
            html: 'Thank you for choosing us!<br>bbsr@amitbooks.com<br>9861140395',
            icon: 'success',
            customClass: {
                popup: 'text-center',
                content: 'text-center'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(resetUserCreds());
                navigate('/');
            }
        });
    };
    

    return (
        <Box sx={{ margin: 'auto', mt: { xs: 0, sm: 2 }, }}>

            <Paper elevation={2} sx={{ p: 2 }}>
           

                <Stepper nonLinear activeStep={activeStep} >
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>


                {allStepsCompleted() ? (
                    <React.Fragment>
                        {/* <Typography variant='h6' align='center' sx={{ mt: 5, mb: 1 }}>
                            Thank You For Shopping
                        </Typography> */}
                       
                        {/* <Typography variant='body1' align='center'>
                            Generate another order

                        </Typography> */}
                        <div>

                            {
                                modalTrigger()
                            }
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button
                                variant='contained'
                                onClick={() => {
                                    // handleReset()
                                    // dispatch(resetCart())
                                    navigate('/')
                                }}>Finish</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ mt: 2, mb: 1, width: '100%' }}>
                            {/* Step {activeStep + 1} */}

                            {/* STEPPER SCREENS */}
                            {
                                activeStep === 0 ? <CartCheckout /> :
                                    activeStep === 1 ? <CredentialFrom setFormSubmited={setFormSubmited} handleClick={handleClick} /> :
                                        // activeStep === 2 ? <CSV />
                                        activeStep === 2 ? <FileDownload formSubmited={formSubmited} />
                                            :
                                            'All done'
                            }
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                            <Button
                                color="inherit"
                                variant='contained'
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button> */}

                            <Button
                                variant='contained'
                                ref={buttonRef}
                                sx={{ display: `${(activeStep === 1 || activeStep === 2 && !formSubmited) && 'none'}` }}
                                // disabled={(activeStep === 1 || activeStep === 2) && !formSubmited}
                                onClick={() => {
                                    { (activeStep === 2 && formSubmited) && handleRestCart() }
                                    handleComplete()
                                }}>
                                {completedSteps() === totalSteps() - 1
                                    ? 'Done'
                                    : 'Next'}
                            </Button>

                            {/* {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                        Step {activeStep + 1} already completed
                                    </Typography>
                                ) : 
                                (
                                    <Button
                                        variant='contained'
                                        ref={buttonRef}
                                        sx={{ display: `${(activeStep === 1 || activeStep === 2 && !formSubmited) && 'none'}` }}
                                        // disabled={(activeStep === 1 || activeStep === 2) && !formSubmited}
                                        onClick={() => {
                                            { ( activeStep === 2 && formSubmited) && handleRestCart() }
                                            handleComplete()
                                        }}>
                                        {completedSteps() === totalSteps() - 1
                                            ? 'Done'
                                            : 'Next'}
                                    </Button>
                                ))} */}
                        </Box>
                    </React.Fragment>
                )}
            </Paper>
        </Box>
    );
}