import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import DownloadIcon from '@mui/icons-material/Download';
import CustomToast from '../../../Components/Toast/CustomToast';

import { Button } from '@mui/material';

const NewPdf = React.forwardRef(({ props }, ref) => {
  const contentRef = useRef(null);
  const cartItems = useSelector(state => state.CartReducer.cartItems)
  const orderCreds = useSelector(state => state.OrderListReducer.orderCreds)
  const currency = useSelector(state => state.PorductReducer.currency)

  // console.log(currency)
  // const itemsTotal = cartItems.reduce((a, c) => a + (c.price * c.qnt), 0)
  const itemsTotal = cartItems.reduce((a, c) => {
    if (c.currency === '$' || c.currency === 'USD') {
      return a + (c.price * c.qnt * currency.dollar);
    }
    else if (c.currency === '€' || c.currency === 'EUR') {
      return a + (c.price * c.qnt * currency.eu);
    }
    else if (c.currency === '£' || c.currency === 'GBP') {
      return a + (c.price * c.qnt * currency.pound);
    }

    else {
      return a + (c.price * c.qnt);
    }
  }, 0);
  const handleDownloadPDF = () => {
    const element = contentRef.current;
    const options = {
      margin: [0.5, 0, 0.5, 0], // top, right, bottom, left margins in inches
      filename: 'amitBookDepot.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', compressPDF: true },
      width: 595,
      height: 842
    };

    html2pdf().set(options).from(element).save();
  };


  // console.log(orderCreds)
  function capitalize(str) {
    return str.replace(/\b\w/g, c => c.toUpperCase());
  }


  const tableStyles = {
    borderCollapse: 'collapse',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.9rem',
  };

  const thStyles = {
    // backgroundColor: '#f5f5f5',
    border: '1px solid #ddd',
    color: '#333',
    fontWeight: 'bold',
    padding: '0.5rem',
    textAlign: 'center',
    textTransform: 'uppercase',
  };

  const tdStyles = {
    border: '1px solid #ddd',
    padding: '0.2rem',
    verticalAlign: 'middle',
    textAlign: 'center'
  };
  const tdStylesLeft = {
    border: '1px solid #ddd',
    padding: '0.2rem',
    verticalAlign: 'middle',
    textAlign: 'left'
  };

  const trStyles = {
    backgroundColor: '#fff',
    transition: 'background-color 0.3s ease',
  };

  const trHoverStyles = {
    backgroundColor: '#f5f5f5',
  };
  const currentDate = new Date(); // create a new Date object with the current date and time
  const year = currentDate.getFullYear(); // get the current year (4 digits)
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // get the current month (0-11), add 1, and pad with leading zeros
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year} `; // format the date string


  return (
    <>

      <Box sx={{ width: '100%', p: 2, }}>
        <Button variant='contained'
          ref={ref}
          sx={{ display: 'none' }}
          endIcon={<DownloadIcon />}
          onClick={handleDownloadPDF}> PDF</Button>



        <Box sx={{ P: 1, width: '90%', margin: 'auto' }} ref={contentRef}>
          {/* <hr /> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <Box sx={{ width: '100%' }}>

              <Typography sx={styles.heading} align='center'>
                {/* INDIAN INSTITUTE OF TECHNOLOGY */}
                {capitalize(orderCreds.institute)}
              </Typography>
              <Typography sx={styles.subHeadingBottom} align='center'>
                      {capitalize(orderCreds.city)}
                    </Typography>
              {/* <Typography sx={styles.heading} align='center'>
                {capitalize(orderCreds.designations)}
              </Typography> */}

            </Box>
            {/* <Box>

              <img
                style={{ width: '5rem', height: '5rem', objectFit: 'cover' }}
                alt='logo'
                src={require('../../../../Ascets/Logos/amitLogo.jpeg')}
              />
            </Box> */}
          </Box>
          {/* <hr /> */}
          <Box sx={{ mt: 4 }}>
            <Typography sx={styles.subHeading}>
              {/* PURCHASE ORDER NO. {uuidv4()} */}
              PURCHASE ORDER NO. 
            </Typography>
            <Typography sx={styles.subHeading}>
              DATE. {formattedDate}
            </Typography>

            <Typography sx={[styles.heading, { mt: 5 }]} align='center'>
              PURCHASE ORDER

            </Typography>

            <Typography sx={[styles.subHeading, { mt: 1 }]}>
              To,
            </Typography>
            <Typography sx={styles.subHeading}>
              AMIT BOOK DEPOT
            </Typography>
            <Typography sx={styles.subHeading}>
              830,Jayadev Vihar
            </Typography>
            <Typography sx={styles.subHeading}>
              Bhubaneswar
            </Typography>
            <Typography sx={styles.subHeading}>
              Email- <a>bbsr@amitbooks.com</a>
            </Typography>
            <Typography sx={styles.subHeading}>
              M-9861140395
            </Typography>





          </Box>
          <Box>
            <Typography sx={[styles.text, { mt: 3 }]}>
              Sir(s),
            </Typography>

            <Typography sx={[styles.text, { mt: 3,mb:2 }]}>
              You are requested to supply the book(s) as mentioned below,along with the invoice copy on the terms agree upon as enclosed in annexure.
            </Typography>





          </Box>
          <Box>


            <table style={tableStyles}>
              {/* <caption>My Table</caption> */}
              <thead>
                <tr>
                  <th style={thStyles}>Sn</th>
                  <th style={thStyles}>ISBN</th>
                  <th style={thStyles}>Author</th>
                  <th style={thStyles}>Title</th>
                  <th style={thStyles}>Publisher</th>
                  <th style={thStyles}>Currency</th>
                  <th style={thStyles}>Price</th>
                  <th style={thStyles}>Qty</th>
                  {/* <th style={thStyles}>Vol_Set</th> */}
                  <th style={thStyles}>Edition</th>
                  {/* <th style={thStyles}>Stock</th> */}
                </tr>
              </thead>
              <tbody>
                {cartItems.map((row, index) => (
                  <tr key={index}>
                    <td style={tdStyles}>{index + 1}</td>
                    <td style={tdStyles}>{row.isbn}</td>
                    <td style={tdStylesLeft}>{row.author}</td>
                    <td style={tdStylesLeft}>{row.title}</td>
                    <td style={tdStylesLeft}>{row.publisher}</td>
                    <td style={tdStyles}>{row.currency}</td>
                    <td style={tdStyles}>{row.price}</td>
                    <td style={tdStyles}>{row.qnt}</td>
                    {/* <td style={tdStyles}>{row.vol_set}</td> */}
                    <td style={tdStyles}>{row.edition}</td>
                    {/* <td style={tdStyles}>{row.newbook?'NO':'YES'}</td> */}

                  </tr>
                ))}
              </tbody>
            </table>
            <Box>
              {/* <hr /> */}
              <Box sx={{ mt: 2 }}>



                <Typography sx={[styles.subHeading, { textAlign: 'right', textDecoration: 'underline' }]}>
                  Total Gross order Value Rs. {itemsTotal}

                </Typography>


                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>

                  <Box>

                    <Typography sx={styles.subHeading}>
                      Copy Forwarded to:
                    </Typography>
                    <ol>
                      <li  > <b>Registrar</b></li>
                      <li> <b>Account section</b></li>
                      <li> <b>Order File</b></li>
                    </ol>
                  </Box>
                  <Box>


                    <Typography sx={styles.subHeadingBottom} >
                      {capitalize(orderCreds.designations)}
                    </Typography>
                    
                    {/* <Typography sx={{mt:3,fontWeight:'bold'}}>
                      Chief Librarin
                    </Typography> */}
                    {/* <Typography sx={styles.text}>
                      (As mentioned in login page)
                    </Typography> */}
                  </Box>

                </Box>

                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: '600' }} color='primary'>
                    Total
                  </Typography>
                  <Typography sx={{ fontWeight: '600' }} color='primary'>
                    {itemsTotal + (itemsTotal * 0.28)}
                  </Typography>
                </Box> */}

              </Box>

              {/* <Typography variant='body1' sx={{ mt: 1, lineHeight: 1, fontSize: 12 }}>
                <b>Head Office</b>
              </Typography>
              <Typography variant='body1' sx={{ mt: 1, lineHeight: 1, fontSize: 12 }}>
                Ph-0612-2300819
              </Typography>

              <Typography variant='body1' sx={{ mt: 1, lineHeight: 1, fontSize: 12 }}>
                AMIT BOOK DEPOT
                1st Floor, Tulsi Appartment
                Govind Mitra Road
                Patna - 800004
              </Typography> */}

              <Box>
                <Typography>

                </Typography>
              </Box>

            </Box>
          </Box>

        </Box>
      </Box>
     
    </>
  );
})

export default NewPdf;

const styles = {
  heading: {
    fontWeight: 'bold',
    fontSize: 20
  },
  subHeading: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  subHeadingBottom: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'centerf'
  },
  text: {
    fontSize: 12
  }
}