import React from 'react'
// import { HashRouter as BrowserRouter, Routes, Route } from 'react-router-dom'
import Cart from './Cart/Cart'
import Home from './Home/Home'
import Checkout from './CheckOut/Checkout'
import {
    Routes,
    Route,
} from "react-router-dom";
import Order from './Order/Order'
import ProtectedRoute from '../../Protected Route/ProtectedRoute'


export default function RoutesNavigation() {



    return (
        
        <Routes path='/' >
            <Route index element={<ProtectedRoute Component={Home } />} />
            {/* <Route path='completedOrders' element={<ProtectedRoute Component={Menu } />} /> */}
            <Route path='cart' element={<ProtectedRoute Component={Cart } />} />
            {/* <Route path='settings' element={<ProtectedRoute Component={Settings } />} /> */}
            <Route path='order' element={<ProtectedRoute Component={Order } />} />
            <Route path='checkout' element={<ProtectedRoute Component={Checkout } />} />


        </Routes>

    )
}
