import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';


function LevelSelector({ filterState, setFilterState, reset }) {
  const { products } = useSelector(state => state.PorductReducer)


  const uniqueLevel = React.useMemo(() => {
    return  products ? products.reduce((acc, product) => {
  if (!acc.includes(product.level_board)) {
    acc.push(product.level_board);
  }
  return acc;
}, []) : [];
  }, [products])


  const [value, setValue] = React.useState(uniqueLevel[0]);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    setValue([])
    setInputValue('')
  }, [reset])
  return (
    <FormControl sx={{ width: '100%', marginLeft: 1 }}>

      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setFilterState({ ...filterState, level_board: newValue })
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="Level"
        options={uniqueLevel}
        getOptionLabel={(option) => typeof option === 'string' ? option : ''}
        isOptionEqualToValue={(option, value) => option === value}

        size='small'
        renderInput={(params) => <TextField {...params} label="Level/Board" />}
      />
    </FormControl>
  );
}
const MemoizedAutocomplete = React.memo(Autocomplete);

export default LevelSelector;
