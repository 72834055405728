import React, { useMemo, useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

function CategorySelector({ filterState, setFilterState, reset }) {

  const { products } = useSelector(state => state.PorductReducer)



  // memoize the categories array using useMemo
  const categories = useMemo(() => {
    return products ? products.reduce((acc, product) => {
      if (!acc.includes(product.category)) {
        acc.push(product.category);
      }
      return acc;
    }, []) : [];
  }, [products]);

  const [value, setValue] = useState(categories[0]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue([])
    setInputValue('')
  }, [reset])

  return (
    <FormControl sx={{ width: '100%', marginLeft: 1 }}>

      {/* Memoize the Autocomplete component */}
      <MemoizedAutocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setFilterState({ ...filterState, category: newValue })
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="Category"
        size='small'
        getOptionLabel={(option) => typeof option === 'string' ? option : ''}
        isOptionEqualToValue={(option, value) => option === value}
        options={categories}
        renderInput={(params) => <TextField {...params} label="Category" />}
      />
    </FormControl>
  );
}

// Define a memoized version of the Autocomplete component
const MemoizedAutocomplete = React.memo(Autocomplete);

export default CategorySelector;
