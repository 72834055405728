import { Box } from '@mui/system'
import React from 'react'
import CustomStepper from '../../Components/Custom/CustomStepper'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

export default function Checkout() {
  const navigate = useNavigate()
  return (
    <Box>
      <IconButton
        onClick={() => navigate(-1)}
        >
        <KeyboardBackspaceIcon color='primary' />
      </IconButton>
      <CustomStepper />
    </Box>
  )}
