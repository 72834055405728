import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
export default function CsvFile() {
    const [csvData, setCsvData] = useState('');

    const orderList=useSelector(state=>state.OrderListReducer.orderList)
    // const CsvDownload = () => {
    //     const table = document.getElementById('my-table');
    //     const rows = table.querySelectorAll('tr');
    //     const csvArray = [];
    //     for (let i = 0; i < rows.length; i++) {
    //       const row = rows[i];
    //       const rowData = [];
    //       const cells = row.querySelectorAll('td, th');
    //       for (let j = 0; j < cells.length; j++) {
    //         rowData.push(cells[j].innerText);
    //       }
    //       csvArray.push(rowData.join(','));
    //     }
    //     const csvString = csvArray.join('\n');
    //     setCsvData(csvString);
    //     const blob = new Blob([csvString], { type: 'text/csv' });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'amit_book_depot.csv';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };


    // const thStyles = {
    //     backgroundColor: '#f5f5f5',
    //     color: '#333',
    //     fontWeight: 'bold',
    //     padding: '0.5rem',
    //     textAlign: 'center',
    //     textTransform: 'uppercase',
    //   };
    
    //   const tdStyles = {
    //     border: '1px solid #ddd',
    //     padding: '0.5rem',
    //     verticalAlign: 'middle',
    //     textAlign:'center',
    //     horizontalAlign:'center'
    //   };
      // const trStyles = {
      //   backgroundColor: '#fff',
      //   transition: 'background-color 0.3s ease',
      // };
    

      const headers = ['ISBN', 'Author', 'Title', 'Publisher', 'Currency', 'Price', 'Qty', 'Edition',];
      const data = orderList.map(item => [item.isbn, item.author, item.title, item.publisher, item.currency, item.price, item.order_qty, item.edition]);
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Cart Items');
    
      function downloadExcelFile() {
        XLSX.writeFile(workbook, 'amit_bookDepot.xls');
      }
    return (
        <div>
            {/* <table id="my-table" style={{display:'none'}}>
             
            <thead>
                <tr >
                  <th style={thStyles}>ISBN</th>
                  <th style={thStyles}>Author</th>
                  <th style={thStyles}>Title</th>
                  <th style={thStyles}>Publisher</th>
                  <th style={thStyles}>Currency</th>
                  <th style={thStyles}>Price</th>
                  <th style={thStyles}>Quantity</th>
                  <th style={thStyles}>Edition</th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((row, index) => (
                  <tr key={index} >
                    <td style={tdStyles}>{row.isbn}</td>
                    <td style={tdStyles}>{row.author}</td>
                    <td style={tdStyles}>{row.title}</td>
                    <td style={tdStyles}>{row.publisher}</td>
                    <td style={tdStyles}>{row.currency}</td>
                    <td style={tdStyles}>{row.price}</td>
                    <td style={tdStyles}>{row.required_qnt}</td>
                    <td style={tdStyles}>{row.edition}</td>

                  </tr>
                ))}
              </tbody>
            </table>
            <Button onClick={CsvDownload}>Download CSV</Button> */}
            <Button onClick={downloadExcelFile}>Download xlsx</Button>
        </div>
    )
}



