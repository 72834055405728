import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// console.log(process.env.REACT_APP_BASE_URL)


//  export const fetchSettings = createAsyncThunk("fetchSettings", async () => {
//     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/hotel/api/pro_api.php`);
//     // return response.json();
//     console.log(response.data)
// });

export const fetchSettings = createAsyncThunk(
  'fetchSettings',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/hotel/api/pro_api.php`, {
        params
      });
      // console.log(response)
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const addFilter = createAction('Filter')

const initialState = {
  saleTax: '5%',
  inventory: 'no',
  status: 'idle',
  error: null,
  filteredPro: [],
  currency: '$',
  kitchenSlip: 'no'
}


const settingSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    filterProducts:
      (state, action) => {
        if (action.payload === 'All') {
          state.filteredPro = state.products
        }
        else {
          state.filteredPro = state.products.filter(item => item.categories_name === action.payload)
        }


      }
  },
  extraReducers: {
    [fetchSettings.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.products = action.payload;
      state.filteredPro = action.payload;
    },
    [fetchSettings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    }
  }
});

export const { filterProducts } = settingSlice.actions
export default settingSlice.reducer

