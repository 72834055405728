import React from 'react'
import { Box } from '@mui/system'

import {  Paper } from '@mui/material'
import FilterDiv from './FilterDiv'
import { useSelector } from 'react-redux'
import Spinner from '../../Components/Spinner'
import DataTable from '../../Components/Table/DataTable'
import { resetUserCreds } from '../../../Redux/orderReducer'
import { useDispatch } from 'react-redux'
import CustomLoader from '../../Components/Custom/CustomLoader'

export default function Home() {
    const [open, setOpen] = React.useState(true)
    const productStatus = useSelector(state => state.PorductReducer.productStatus)
    
    const dispatch=useDispatch()
  
    dispatch(resetUserCreds())

    return (
        <>{productStatus !== 'succeeded' ?
        <>
            <CustomLoader/>
             {/* <Spinner /> */}
        </>
            :
            <Paper elevation={2} sx={{ p: 2 }} >

                {/* <Box sx={{ right: 5, top: 3, }}>

                    <IconButton
                        onClick={() => setOpen(!open)}
                        sx={{ display: `${open ? 'none' : 'block'}` }}
                    >
                        <TuneIcon color='primary' />
                    </IconButton>
                    <IconButton
                        onClick={() => setOpen(!open)}
                        sx={{ display: `${!open ? 'none' : 'block'}` }}>
                        <CloseIcon color='error' />
                    </IconButton>
                </Box> */}

                <Box sx={{ mb: 1, display: `${open ? 'block' : 'none'}` }}>
                    <FilterDiv />

                </Box>
                {/* <PaginationTable /> */}
                <DataTable />

            </Paper>
        }
        </>
    )
}
