import * as React from 'react';
// import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListIcon from '@mui/icons-material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useTranslation } from "react-i18next";
import { MenuList, Typography, useTheme } from '@mui/material';
import { makeStyles, withStyles } from "@material-ui/styles";
import { useNavigate, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { resetCart } from '../../../../Redux/cartReducer';
import { handleLogout } from '../../../../Redux/authReducer';
import { useDispatch } from 'react-redux';



const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    }
}));



// export default function ListItems({ setCurrentScreen, currentScreen }) {
export default function ListItems({ setCurrentScreen, currentScreen }) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation()
    const theme = useTheme()
    const dispatch = useDispatch()

    // React.useEffect(() => {
    //     console.log(location.pathname)
    // }, [location])

    const navigation = (link) => {
        navigate(link)
    }

    const style = {
        listButton: {
            width: '4rem',
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center', borderRadius: 5,
            // marginLeft:2
        }
    }

    const ListItemButton = withStyles({
        root: {
            // "&$selected": {
            //     backgroundColor: "red",
            //     color: "white",
            //     "& .MuiListItemIcon-root": {
            //         color: "white"
            //     }
            // },
            "&$selected:hover": {
                backgroundColor: "purple",
                color: "white",
                "& .MuiListItemIcon-root": {
                    color: "white"
                }
            },

            // "&:hover": {
            //     backgroundColor: theme.palette.primary.light,
            //     color: theme.palette.text.secondary,
            //     "& .MuiListItemIcon-root": {
            //         color: theme.palette.text.primary
            //     }
            // }
        },
        selected: {}
    })(ListItem);

    return (

        <React.Fragment>
            <ListItemButton

                sx={[style.listButton, { backgroundColor: location.pathname == '/' ? theme.palette.primary.main : 'none', }]}

                
                onClick={() => {
                    navigation('/')
                }} >
                <ListItemIcon >
                    <MenuBookIcon sx={{ fontSize: 30, margin: 'auto', color: location.pathname === '/' ? theme.palette.text.light : 'none', }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/' ? theme.palette.text.light : 'none' }} >{t('home')}</Typography>
            </ListItemButton>






            <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/order' ? theme.palette.primary.main : 'none' }]}
                
                onClick={() => {
                    navigate('order')
                }} >
                <ListItemIcon>
                    <AddCircleIcon sx={{ color: location.pathname === '/order' ? theme.palette.text.light : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/order' ? theme.palette.text.light : 'none' }} >Add New</Typography>
            </ListItemButton>


            <ListItemButton sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', aignItems: 'center', width: '4rem', mt: 4 }}
            onClick={()=>{
                dispatch(handleLogout())
                dispatch(resetCart())
            }}
            >
            
                <ListItemIcon>
                    <LogoutIcon color={'error'} sx={{ fontSize: 26, margin: 'auto' }} />
                </ListItemIcon>
                <Typography color={'error'} sx={{ cursor: 'default', fontSize: 10, }} >Logout</Typography>
            </ListItemButton>


            {/* <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/cart' ? theme.palette.primary.main : 'none' }]}
                
                onClick={() => {
                    navigate('cart')
                }}

            >
               
                <ListItemIcon>
                  
                    <ShoppingCartIcon sx={{ color: location.pathname === '/cart' ? theme.palette.text.light : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/cart' ? theme.palette.text.light : 'none' }} >{t('cart')}</Typography>

            </ListItemButton> */}


            {/* <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/orderList' ? theme.palette.primary.main : 'none' }]}
                
                onClick={() => {
                    navigate('orderList')
                }}>
                <ListItemIcon>
                    <ListAltIcon sx={{ color: location.pathname === '/orderList' ? theme.palette.text.light : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/orderList' ? theme.palette.text.light : 'none' }} >{t('order_list')}</Typography>

            </ListItemButton> */}


            {/* <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/history' ? theme.palette.primary.main : 'none' }]}
                
                onClick={() => {
                    navigate('history')
                }} >
                <ListItemIcon>
                    <HistoryIcon sx={{ color: location.pathname === '/history' ? theme.palette.text.light : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/history' ? theme.palette.text.light : 'none' }} >{t('history')}</Typography>
            </ListItemButton> */}


            {/* <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/settings' ? theme.palette.primary.main : 'none' }]}
                
                onClick={() => {
                    navigate('settings')
                }} >
                <ListItemIcon>
                    <SettingsIcon sx={{ color: location.pathname === '/settings' ? theme.palette.text.light : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/settings' ? theme.palette.text.light : 'none' }} >{t('settings')}</Typography>

            </ListItemButton> */}
        </React.Fragment>
    )
}

