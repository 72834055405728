import * as React from 'react';
import { Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useTheme } from '@mui/material';
import { Button } from '@mui/material';
// import { useCartContext } from '../../../Context/CartContext';
import { useDispatch, useSelector } from 'react-redux';
import { incrementQnt, decrementQnt, removeFromCart } from '../../../Redux/cartReducer';


export default function CartItemCard({ item }) {
    // console.log(item)
    // const { incrimentQnt, decrimentQnt, removeItem } = useCartContext()
    const currency = useSelector(state => state.PorductReducer.currency)
    const dispatch = useDispatch()
    const theme = useTheme()
    const style = {
        button: {

            padding: '3px',
            backgroundColor: theme.palette.primary.main,
            fontWeight: 'light',
            width: '1.5rem',
            borderRadius: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // color: theme.palette.primary.contrastText
            color: theme.palette.primary.contrastText

        }, typography: {
            overflow: 'hidden', fontSize: 16, textAlign: 'left', overflow: 'hidden', height: '1.5rem'
        }
    }
    // console.log(currency)

    return (
        <>
            <Box

                sx={{
                    display: 'flex', alignItems: 'left',
                    width: '100%', p: 1, position: 'relative', borderRadius: 3, mt: 1,
                    // backgroundColor: theme.palette.primary.light,
                }}>



                {/* IMAGE BOX */}
                <Grid container alignItems={'center'}>
                    {/* <Grid item xs={2} sm={2} xl={2}>
                        <img
                            src='https://res.cloudinary.com/dtsvrxssd/image/upload/v1665930547/cld-sample-4.jpg'
                            style={{ height: '2rem', width: '2rem', objectFit: 'cover', borderRadius: 5 }}
                        />

                    </Grid> */}


                    <Grid item xs={9} sm={9} xl={9} >

                        <Box sx={{ width: '3rem%', overflow: 'hidden' }}>

                            <Typography sx={style.typography} >

                                {item.title}
                                {/* name of df df d fd f fdfdfd f fd f */}
                            </Typography>
                            <Typography variant='body2'  >
                                ISBN# {item.isbn}
                            </Typography>
                            <Typography variant='body2'  >
                                Author:&nbsp; <b>{item.author}</b>
                            </Typography>
                            <Typography variant='body2'  >
                                <b>  {item.currency + '-' + item.price}</b>
                            </Typography>


                            {item.currency != "RS" &&

                                <Typography variant='body2' color={'error'}>
                                    {'Exchange Rate ' + (item.currency === 'USD' ? currency.dollar :
                                        item.currency === 'GBP' ? currency.pound :
                                            item.currency === 'EUR' ? currency.eu : item.price)}

                                </Typography>

                            }
                            {/* if currency not Rs */}
                            {item.currency != "RS" &&

                                <Typography variant='body2'>
                                    <b>{'Rs- ' + (item.currency === 'USD' ? item.price * currency.dollar :
                                        item.currency === 'GBP' ? item.price * currency.pound :
                                            item.currency === 'EUR' ? item.price * currency.eu : item.price)}
                                    </b>
                                </Typography>

                            }

                            <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', }}>

                                <Box style={style.button}
                                    aria-label="Remove" color='primary'
                                    onClick={() => dispatch(decrementQnt({ item: item }))}
                                    size='small' >
                                    <RemoveIcon fontSize='1rem' />
                                </Box>
                                <Typography sx={{ width: 30, fontSize: '22', fontWeight: '600', textAlign: 'center' }}  >
                                    {item.qnt}
                                </Typography>

                                <Box color={'primary'} style={style.button}
                                    aria-label="Add"
                                    onClick={() => dispatch(incrementQnt({ item: item }))}
                                    size='small'>
                                    <AddIcon fontSize='1rem' />
                                </Box>

                            </Box>
                            {/* <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between', mt: 2 }}>

                                <Typography variant='body2'  >
                                    ${item.price}
                                </Typography>
                                <Typography variant='body2' >
                                    {item.size}
                                </Typography>
                            </Box> */}
                        </Box>
                    </Grid>

                    {/* <Grid item xs={2} md={2} sm={2} lg={2}>
                        <Typography
                            onClick={() => dispatch(removeFromCart({ item: item }))}
                            sx={{ fontSize: 12, textAlign: 'center', color: 'red', cursor: 'pointer' }}>
                            Remove
                        </Typography>
                    </Grid> */}


                    <Grid item xs={3} sm={3} xl={3}>


                        <Box sx={{ justifyContent: 'center', mt: 1, alignItems: 'center', }}>

                            {/* <Typography variant='body2' sx={{ fontWeight: 'bold' }} >
                                ${item.price * item.qnt}
                            </Typography>
                            <Typography variant='body2' >
                                {item.size}
                            </Typography> */}

                            <Button
                                color='error'
                                variant='contained'
                                onClick={() => dispatch(removeFromCart({ item: item }))}
                                sx={{ fontSize: 12, textAlign: 'center', cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}>
                                Remove
                            </Button>
                            {/* <Typography variant='subtitle2'  >
                                Edition-&nbsp;<b>{item.edition}</b>
                            </Typography>
                            {item.newbook ?
                                <Typography variant='body2' color='primary'>
                                    &nbsp;<b>New Book  </b>
                                </Typography> :
                                <Typography variant='body2'  >
                                    Vol Set -&nbsp;<b>{item.vol_set}</b>
                                </Typography>
                            } */}

                            {/* <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', }}>

                                <Box style={style.button}
                                    aria-label="Remove" color='primary'
                                    onClick={() => dispatch(decrementQnt({ item: item }))}
                                    size='small' >
                                    <RemoveIcon fontSize='1rem' />
                                </Box>
                                <Typography sx={{ width: 30, fontSize: '22', fontWeight: '600', textAlign: 'center' }}  >
                                    {item.qnt}
                                </Typography>

                                <Box color={'primary'} style={style.button}
                                    aria-label="Add"
                                    onClick={() => dispatch(incrementQnt({ item: item }))}
                                    size='small'>
                                    <AddIcon fontSize='1rem' />
                                </Box>

                            </Box> */}
                            {/* <Typography variant='body2'  >
                                {item.price*item.qnt}
                            </Typography> */}
                        </Box>



                        {/* <Typography
                            onClick={() => dispatch(removeFromCart({ item: item }))}
                            sx={{ fontSize: 12, textAlign: 'center', color: 'red', mt: 2, cursor: 'pointer' }}>
                            Remove
                        </Typography> */}
                    </Grid>



                </Grid>





            </Box>
            <Divider />

        </>
    );
}
