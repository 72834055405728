import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
 function Subject({filterState, setFilterState,reset}) {
   const { products } = useSelector(state => state.PorductReducer)

  const uniqueSubject =React.useMemo(()=>{
   return products ? products.reduce((acc, product) => {
      if (!acc.includes(product.subject)) {
        acc.push(product.subject);
      }
      return acc;
    }, []) : [];
  
  },[products])
  


  const [value, setValue] = React.useState(uniqueSubject[0]);
  const [inputValue, setInputValue] = React.useState('');
  
React.useEffect(()=>{
  setValue([])
  setInputValue('')
},[reset])

  return (
    <FormControl sx={{ width: '100%', marginLeft: 1 }}>
 
  <MemoizedAutocomplete
        value={value }
        onChange={(event, newValue) => {
          setValue(newValue);
          setFilterState({...filterState,subject:newValue})
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="Category"
        options={uniqueSubject}
        getOptionLabel={(option) => typeof option === 'string' ? option : ''}
        isOptionEqualToValue={(option, value) => option === value}

        // sx={{ width: 300 }}
        size='small'
        renderInput={(params) => <TextField {...params} label="Subject" />}
      />
  </FormControl>
  );
}
const MemoizedAutocomplete = React.memo(Autocomplete);

export default Subject;
