import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
// import LogoIcon from '../../assets/svg/Inposizione-Logo.png'
import { useTranslation } from "react-i18next";
import { useDispatch,useSelector } from 'react-redux';
import { setOrderCreds } from '../../../Redux/orderReducer';


const theme = createTheme();

export default function CredentialForm({setFormSubmited,handleClick}) {
    const { t } = useTranslation();
    const orderCreds = useSelector(state => state.OrderListReducer.orderCreds)

    const dispatch=useDispatch()

    // console.log(orderCreds)
    //Yup Schema
    const schema = yup.object({
        institute: yup.string().trim().required("Institute Required"),
        designations: yup.string().required("Designation Required"),
        email: yup.string().email("Invalid Email").required("Email Required"),
        name: yup.string().trim().required('Name Required'),
        // country: yup.string().trim().required(`${t("country_required")}`),
        city: yup.string().trim().required(`${"City Required"}`),
        phoneNo: yup.string().min(11, "Enter Valid Phone").required("Phone Required")
    }).required();

    const userDetail=JSON.parse(localStorage.getItem('user'))
    // const { registerUser, loading, resStatus, setResStatus } = useUserAuth()
    // const orderCreds=useSelector(state=>state.OrderReducer.orderCreds)
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        dispatch(setOrderCreds(data))
        setFormSubmited(true)
        handleClick()

        // registerUser({ ...data, gender })
        // registerUser({ ...data })
        // console.log(data)


    }

    //gender and email section
    // if (resStatus === 1) {
    //     swal(
    //         {
    //             title: `${t("account_created")}`,
    //             text: `${t("account_created_login")}`,
    //             icon: 'success',
    //         }
    //     )
    //     setResStatus('')
    //     navigate('/login')
    // }
    // if (resStatus === 0) {
    //     swal(
    //         {
    //             title: `${t("error")}`,
    //             text: `${t("some_internal_error")}`,
    //             icon: 'error',
    //         }
    //     )
    //     setResStatus('')
    // }



    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />

                <Box elevation={3}
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2
                        // , mt: '2rem',
                        // height:'10rem'
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    {/* <Typography variant="h5" align="center" gutterBottom>
                        Credentials
                    </Typography> */}
                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="name"
                                    // defaultValue={userDetail.user_name}
                                    defaultValue={orderCreds.name}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="name"
                                            // required
                                            size='small'
                                            fullWidth
                                            id="name"
                                            label="Name"
                                            error={errors.name}
                                            helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="email"
                                    // defaultValue={userDetail.user_email}
                                    defaultValue={orderCreds.email}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            size='small'
                                            id="email"
                                            label={t("email")}
                                            name="email"
                                            autoComplete="email"
                                            error={errors.email}
                                            helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}  lg={6} xl={6}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="country"
                                            label="Country"
                                            name={t("country")}
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.country}
                                            helperText={errors.country?.message}
                                        />} />
                            </Grid> */}

                            {/* 
                            <Grid item xs={12} sm={6}  lg={6} xl={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="city"
                                            label={t("city")}
                                            name="city"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.city}
                                            helperText={errors.city?.message}
                                        />}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="institute"
                                    // defaultValue={userDetail.user_institute}
                                    defaultValue={orderCreds.institute}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            size='small'
                                            name="institute"
                                            label='Institute/College/University'
                                            type="text"
                                            id="institute"
                                            error={errors.institute}
                                            helperText={errors.institute?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="city"
                                    // defaultValue={userDetail.user_institute}
                                    defaultValue={orderCreds.city}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            size='small'
                                            name="city"
                                            label='City'
                                            type="text"
                                            id="institute"
                                            error={errors.city}
                                            helperText={errors.city?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="designations"
                                    // defaultValue={userDetail.user_designation}
                                    defaultValue={orderCreds.designations}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            size='small'
                                            name="designations"
                                            label='Designation'
                                            type="text"
                                            id="designations"
                                            error={errors.designations}
                                            helperText={errors.designations?.message}
                                        />}
                                />
                            </Grid>

                            {/* 
                            <Grid item xs={12} sm={4} lg={4} xl={4}>
                                        

                                <FormControl sx={{minWidth:{sm:100,xs:300}}}>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={gender}
                                        // fullWidth
                                        label={t('gender')}
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={'male'}>{t("male")}</MenuItem>
                                        <MenuItem value={'female'}>{t("female")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="phoneNo"
                                    // defaultValue={userDetail.user_phone}
                                    defaultValue={orderCreds.phoneNo}
                                    control={control}
                                    sx={{ width: 200 }}
                                    render={({ field: { onChange, value } }) =>
                                        <MuiPhoneNumber defaultCountry={'in'}
                                            sx={{ marginTop: 3, width: "100%" }}
                                            // required
                                            onChange={onChange} value={value}
                                            error={errors.phoneNo}
                                            helperText={errors.phoneNo?.message}
                                            options={{
                                                excludeCountries: [''],
                                                disableAreaCodes: false

                                            }}

                                        />} />
                            </Grid>
                        </Grid>
                        <LoadingButton
                            // loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Next
                        </LoadingButton>


                        {/* <Box sx={{ fontSize: 14, textAlign: 'center', marginTop: 4 }}>

                            <Link to="/landing"  >
                                {t("back_to_home")}
                            </Link>
                        </Box> */}
                    </form>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

