import React from 'react'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { Typography, Divider } from '@mui/material'
import CartItemCard from '../../Components/Cards/CartItemCard'
import { useSelector } from 'react-redux'
import { placeOrder } from '../../../Redux/orderReducer'
import { useDispatch } from 'react-redux'
import { resetCart } from '../../../Redux/cartReducer'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid';
import CustomToast from '../../Components/Toast/CustomToast'
import { useNavigate } from 'react-router-dom'

import '../../../App.css'

// console.log(uuidv4())
export default function CartCheckout() {
    const cartItems = useSelector((state) => state.CartReducer.cartItems)
    const currency = useSelector(state => state.PorductReducer.currency)

    // const itemsTotal = cartItems.reduce((a, c) => a + (c.price * c.qnt), 0)
    // console.log(currency.eu)
    const itemsTotal = cartItems.reduce((a, c) => {
        if (c.currency === 'USD') {
            return a + (c.price * c.qnt * currency.dollar);
        }
        else if (c.currency === 'EUR') {
            return a + (c.price * c.qnt * currency.eu);
        }
        else if (c.currency === 'GBP') {
            return a + (c.price * c.qnt * currency.pound);
        }
        else {
            return a + (c.price * c.qnt);
        }
    }, 0);
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [type, setType] = React.useState('')
    const { t } = useTranslation()

    const toastRef = React.useRef(null)
    const handleToast = () => {
        toastRef.current.click()
    }

    React.useEffect(()=>{
cartItems.length<1&&navigate('/')
    },[cartItems])


    let currentDate = new Date();
    let options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const presetDate = new Intl.DateTimeFormat('en-US', options).format(currentDate)

    return (
        <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'space-between' }}>



            <Box id='scroll' sx={{ height: { xs: '22rem', sm: '18rem' }, overflow: 'scroll' }}>

                {
                    cartItems.map((item) => {
                        return <CartItemCard item={item} key={item.book_id} />
                    })
                }

            </Box>
                {/* <Divider /> */}


            {/* total and other components */}
            <Box >

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Typography color={'primary'} sx={{ fontWeight: '600' }}>
                        Total
                    </Typography>
                    <Typography sx={{ fontWeight: '600' }}>
                        {itemsTotal}
                    </Typography>
                </Box>


                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: '600' }}>
                        Tax 28% (VAT include)
                    </Typography>
                    <Typography sx={{ fontWeight: '600' }}>
                        {(itemsTotal * 0.28).toFixed(2)}
                    </Typography>
                </Box> */}

                {/* <Divider variant='outlined' />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: '600' }} color='primary'>
                        Total
                    </Typography>
                    <Typography sx={{ fontWeight: '600' }} color='primary'>
                        {itemsTotal + (itemsTotal * 0.28)}
                    </Typography>
                </Box> */}

            </Box>
        

            <CustomToast ref={toastRef} message={"Order submitted"} />

        </Box>
    )
}
