import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
export default function ProtectedRoute({ Component }) {
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.Auth.isAuthenticated)
    React.useEffect(() => {
        // console.log(isAuthenticated)
        !isAuthenticated ? navigate('login'):
        navigate('/')
    }, [isAuthenticated])
    return (
        <>
            <Component />
            {/* {isAuthenticated ? <Component />
                : navigate('/loign')
            } */}

        </>
    )
}
