import React from 'react'
import { IconButton } from '@mui/material'
import { Typography, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import { increment } from '../../../Redux/productReducer';

export default function QuantityInput({ row, setQnt }) {
    const [count, setCount] = React.useState('1')
    const dispatch = useDispatch()

    const theme = useTheme()
    const style = {
        button: {

            padding: '3px',
            backgroundColor: theme.palette.primary.main,
            fontWeight: 'light',
            width: '1.5rem',
            borderRadius: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // color: theme.palette.primary.contrastText
            color: theme.palette.primary.contrastText

        }, typography: {
            overflow: 'hidden', fontSize: 16, textAlign: 'left', overflow: 'hidden', height: '1.5rem'
        }
    }
    React.useEffect(() => {
        setQnt(count)
    }, [count])

    return (
        <div>

            <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>

                {/* <IconButton aria-label="Remove" color='primary' onClick={() => {
                    if (count > 1)
                        setCount(count - 1)
                }}>
                    <RemoveIcon />
                </IconButton> */}
                {/* <Typography sx={{ width: 50, textAlign: 'center' }} variant='h6'>
                    {count}
                </Typography> */}

                <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', }}>

                    <Box style={style.button}
                        aria-label="Remove" color='primary'
                        onClick={() => !count < 1 && setCount(+count - 1)}
                        size='small' >
                        <RemoveIcon fontSize='1rem' />
                    </Box>
                    <input
                        value={count}
                        type="number" 
                        // pattern="[0-9]{5}\s[0-9]{3}"
                        min={1}
                        style={{ padding: '1px', maxWidth: '3rem', textAlign: 'center', border: 'none', outline: 'none', backgroundColor: 'rgb(234,234,234)' }}
                        onChange={(e) => {

                            setCount(e.target.value >= 1 && e.target.value)
                            setQnt(e.target.value >= 1 && e.target.value)

                        }}
                        onBlur={() => count < 0 && setCount(1)}
                        onFocus={() => setQnt(1)}
                    />

                    <Box color={'primary'} style={style.button}
                        aria-label="Add"
                        onClick={() => setCount(+count + 1)}
                        size='small'>
                        <AddIcon fontSize='1rem' />
                    </Box>

                </Box>
                {/* <IconButton aria-label="Add" color='primary' onClick={() => {
                    if (count < 10)
                        setCount(count + 1)
                }}>
                    <AddIcon />
                </IconButton> */}

            </Box>
        </div>
    )
}
