import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
// import LogoIcon from '../../assets/svg/Inposizione-Logo.png'
import swal from 'sweetalert';
import ExitingOrders from './ExitingOrders';
import { addOrder } from '../../../Redux/orderReducer';
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'uid'



const theme = createTheme();

export default function Order() {
    const dispatch = useDispatch()
    const [PopUp, setPopUp] = React.useState()
    const products = useSelector((state) => state.PorductReducer.products)
    // const selector=useSelector()
    //Yup Schema


    const handleExist = () => {

    }


    const schema = yup.object({
        title: yup.string().trim().required("Title Required"),
        author: yup.string().required("Author Required"),
        required_qnt: yup.string().required("Quantity Required"),
        // name: yup.string().trim().required('Name Required'),
        currency: yup.string().trim().required("Currency Requried"),
        price: yup.string().trim().required("Price Required"),
        // phoneNo: yup.string().min(11, "Enter Valid Phone").required("Phone Required")
    }).required();


    const [gender, setGender] = React.useState('');
    // const { registerUser, loading, resStatus, setResStatus } = useUserAuth()
    const navigate = useNavigate()
    const orderList = useSelector((state) => state.OrderListReducer.orderList)
    const cartItems = useSelector((state) => state.CartReducer.cartItems)
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        // if(orderList.filter(item=>item.isbn!=data.isbn))
        const exist = orderList.find((elem) => elem.isbn === data.isbn);
        const isbnExist = products.find((elem) => elem.isbn === data.isbn);
        if (isbnExist) {
            setPopUp(true)
        }

        else if (exist) {
            setPopUp(true)

        }

        else if (!exist) {

            dispatch(addOrder({ ...data, book_id: uid(), vol_set: 'none', newbook: true }))
            reset({ title: '', publisher: "", edition: '', price: '', currency: '', required_qnt: '', isbn: '', author: '' })
            setPopUp(false)
        }


    }
    const currencies = [
        {
            value: 'USD',
            label: 'USD',
        },
        {
            value: 'EUR',
            label: 'EUR',
        },
        {
            value: 'RS',
            label: 'RS',
        },
        {
            value: 'GBP',
            label: 'GBP',
        },
    ];


    //gender and required_qnt section
    if (PopUp) {
        swal(
            {
                title: `Alert`,
                text: `Book already exist`,
                icon: 'error',
            }
        )

    }
    // if (resStatus === 0) {
    //     swal(
    //         {
    //             title: `${t("error")}`,
    //             text: `${t("some_internal_error")}`,
    //             icon: 'error',
    //         }
    //     )
    //     setResStatus('')
    // }


    const handleChange = (e) => {
        const exist = products.find((elem) => elem.isbn === e);

        if (exist) {
            console.log(exist)
            setPopUp(true)
        } else {
            setPopUp(false)
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <Container >
                <CssBaseline />

                <Paper elevation={3}
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5
                        , mt: '2rem',
                        // height:'10rem'
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography variant="h5" align="center" gutterBottom>
                        Add New Book
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="isbn"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            // autoComplete="given-name"
                                            name="isbn"
                                            // onChange={(e) => handleChange(e.target.value)}
                                            // required
                                            fullWidth
                                            id="isbn"
                                            type='text'
                                            label="ISBN(optional)"
                                            defaultValue=""
                                        // error={errors.name}
                                        // helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="publisher"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="publisher"
                                            label="Publisher(optional)"
                                            name="publisher"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.publisher}
                                            helperText={errors.publisher?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="edition"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="edition"
                                            label="Edition(optional)"
                                            name="edition"
                                            autoComplete="family-name"
                                            defaultValue=""
                                        // error={errors.edition}
                                        // helperText={errors.edition?.message}
                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="required_qnt"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            type='number'
                                            fullWidth
                                            min={1}
                                            id="required_qnt"
                                            label="Required Quantity"
                                            name="required_qnt"

                                            defaultValue=""
                                            error={errors.required_qnt}
                                            helperText={errors.required_qnt?.message}
                                        />}
                                />
                            </Grid>



                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="title"
                                            label='Title'
                                            type="text"
                                            id="title"
                                            defaultValue=""
                                            error={errors.title}
                                            helperText={errors.title?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="author"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="author"
                                            label='Author'
                                            type="text"
                                            id="author"
                                            defaultValue=""
                                            error={errors.author}
                                            helperText={errors.author?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} lg={3} xl={3}>

                                <Controller
                                    name="currency"
                                    control={control}
                                    defaultValue="RS" // Set a default value
                                    render={({ field }) => (
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            {...field}
                                            label="Currency"
                                            fullWidth
                                            error={errors.currency}
                                            helperText={errors.currency?.message}
                                        >
                                            {currencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} lg={3} xl={3}>
                                <Controller
                                    name="price"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="price"
                                            type='number'
                                            label='Price'
                                            id="price"
                                            defaultValue=""
                                            error={errors.price}
                                            helperText={errors.price?.message}
                                        />}
                                />
                            </Grid>



                        </Grid>
                        <LoadingButton
                            // loading={loading}
                            // disabled={PopUp}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Order
                        </LoadingButton>



                    </form>


                    <Box sx={{ mt: 2 }}>
                        <Typography align='center'>
                            Order List
                        </Typography>
                        <ExitingOrders />
                    </Box>
                </Paper>

            </Container>
        </ThemeProvider>
    );
}

