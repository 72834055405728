import { Box, Typography } from '@mui/material'
import React from 'react'
// import CSV from './Csv'
import CsvDownload from './CsvDownload'
import NewPdf from './NewPdf'
// import PdfFile from './PdfFile'
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import CustomToast from '../../../Components/Toast/CustomToast';


export default function FileDownload({ formSubmited }) {
    const pdfRef = React.useRef(null)
    const xlsxRef = React.useRef(null)


//TOAST MESSAGE
const pdRef = React.useRef(null)
const exelRef = React.useRef(null)
const hanlePdfToast = () => {
    pdRef.current.click()
}
const hanleExlToast = () => {
    exelRef.current.click()
}


    const handlePdf = () => {
        hanlePdfToast()
        pdfRef.current.click()
    }
    const handleXlsx = () => {
        hanleExlToast()
        xlsxRef.current.click()
    }

  

    return (
        <Box >
            {/* <Typography align='center' variant='h6'>
                Download Order File
            </Typography> */}
            <Typography align='center' sx={{ display: `${!formSubmited ? 'block' : 'none'}` }} color={'error'}>
                Fill order CredentialForm
            </Typography>
            <Box sx={{ display: `${formSubmited ? 'block' : 'none'}`, mt: 2 }}>
                {/* <CSV /> */}
                {/* <PdfFile /> */}
                <NewPdf ref={pdfRef} />
                <CsvDownload ref={xlsxRef} />

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>

                    <Button
                        variant='contained'
                        onClick={() => handlePdf()}
                        endIcon={<DownloadIcon />}
                    >
                        Pdf
                    </Button>
                    <Button
                        onClick={() => handleXlsx()}
                        variant='contained'
                        sx={{ ml: 1 }}
                        endIcon={<DownloadIcon />}
                    >
                        xlsx
                    </Button>

                </Box>
            </Box>
            <CustomToast ref={pdRef} message={'PDF Downloaded'} />
            <CustomToast ref={exelRef} message={'Xlsx Downloaded'} />


        </Box>
    )
}
