import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// console.log(process.env.REACT_APP_BASE_URL)


//  export const fetchProducts = createAsyncThunk("fetchProducts", async () => {
//     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/hotel/api/pro_api.php`);
//     // return response.json();
//     console.log(response.data)
// });


const products = [];
// export const fetchProducts = createAsyncThunk(
//   'fetchProducts',
//   async (_, thunkAPI) => {
//     try {
//       const urls = [
//         `${process.env.REACT_APP_URL}/api.php?type=booklist&page=1`,
//         // `${process.env.REACT_APP_URL}/api.php?type=booklist&page=2`,
//         // `${process.env.REACT_APP_URL}/api.php?type=booklist&page=3`,
//         // `${process.env.REACT_APP_URL}/api.php?type=booklist&page=4`
//       ];

//       const responses = await Promise.all(urls.map(url => axios.get(url)));
//       const products = responses.flatMap(response => response.data.data);

//       return products;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );


export const fetchProducts = createAsyncThunk(
  'fetchProducts',
  async (_, thunkAPI) => {
    try {
      const urls = [
        `${process.env.REACT_APP_URL}/api.php?type=booklist&page=1`,
        `${process.env.REACT_APP_URL}/api.php?type=booklist&page=2`,
        `${process.env.REACT_APP_URL}/api.php?type=booklist&page=3`,
        `${process.env.REACT_APP_URL}/api.php?type=booklist&page=4`
      ];

      const products = [];
      let load=0
      
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        load=i+1;
        const response = await axios.get(url);
        const pageData = response.data.data;
        products.push(...pageData);
        // temp={products:products,load:load++}
        // console.log(temp)
        thunkAPI.dispatch(fetchProducts.fulfilled({ products: products.slice(), load }, {meta: i + 1}));
      }
      
      return {products, load};
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);





export const filterBooks = createAsyncThunk(
  'filterBooks',
  async (params, thunkAPI) => {
    console.log(params)
    // "https://admin.amitbooks.in/api//api.php?type=booklistsearch&category=&subject=&course=&branch=&level_board=PATSTK"
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api.php?type=booklistsearch&category=${params.category}&subject=${params.subject}&level_board=${params.level_board}&course=${params.courses}&branch=${params.branch}`);
      // console.log(response)
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCurrency = createAsyncThunk(
  'fetchCurrency',
  async (thunkAPI) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api.php?type=currency`);
      // console.log(response)
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const addFilter = createAction('Filter')

const initialState = {
  products: [],
  data: [],
  status: 'idle',
  filterSts: 'idle',
  productStatus: 'idle',
  error: null,
  filteredPro: [],
  categories: [],
  currentProduct: null,
  load:0,
  currency: {

    dollar: 0, eu: 0, pound: 0
  }




}

let hasRun = false;

const productSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {

    setProduct: (state, action) => {
      console.log('helo')
      // state.products = action.payload
      // state.filteredPro = action.payload
    },
    increment: (state, action) => {
      // if (+item.product_quantaty < 10)
      // console.log(action.payload)
      state.products = state.products.map((x) => x.book_id == action.payload.item.book_id ? { ...action.payload.item, order_qty: +action.payload.item.order_qty + +action.payload.value } : x)
      // console.log(state.products)
      // localStorage.state.products("products", JSON.stringify(state.products.map((x) => x.book_id == action.payload.item.book_id ? { ...action.payload.item, product_quantaty: +action.payload.item.product_quantaty + 1 } : x)))
    },
    //DECRIMENT IN QUANTITY
    decrementQnt: (state, action) => {
      if (action.payload.item.product_quantaty > 1)
        state.products = state.products.map((x) => x.book_id == action.payload.item.book_id ? { ...action.payload.item, product_quantaty: action.payload.item.product_quantaty - 1 } : x)

    },
    incrementQntByAmount: (state, action) => {
      state.products += action.payload.count
    },
    applyFilter: (state, action) => {
      // console.log(action.payload)
      state.filteredPro =state.products
      // state.filteredPro = state.products.filter((item) =>
      //   (action.payload.category == undefined || item.category == action.payload.category || action.payload.category == "")
      //   && (action.payload.subject == undefined || item.subject == action.payload.subject || action.payload.subject == "")
      //   && (action.payload.level_board == undefined || item.level_board == action.payload.level_board || action.payload.subject == "")
      //   && (action.payload.cources == undefined || item.cources == action.payload.cources || action.payload.cources == "")
      //   && (action.payload.branch == undefined || item.branch == action.payload.branch || action.payload.branch == "")
      // );
    }

    ,
    resetFilter: (state, action) => {
      // state.filteredPro = []
      state.filteredPro = state.products
      // state.filter = {...state.filter,...(action.payload)}
    }
  },
  extraReducers: {
    [fetchProducts.pending]: (state, action) => {
      state.productStatus = 'loading';
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.load=action.payload.load
      state.products = action.payload.products;
      state.filteredPro = action.payload.products;
      state.productStatus = 'succeeded';

    },

    [fetchProducts.rejected]: (state, action) => {
      state.productStatus = 'failed';
      state.error = action.payload;
    },




    [filterBooks.pending]: (state, action) => {
      state.filterSts = 'loading';
    },
    [filterBooks.fulfilled]: (state, action) => {
      // console.log(action.payload)
      if (action.payload != null)
        state.filteredPro = action.payload
      state.filterSts = 'succeeded';



      // state.products = action.payload;
      // state.filteredPro = action.payload;
    },
    [filterBooks.rejected]: (state, action) => {
      state.filterSts = 'failed';
      state.error = action.payload;
    },




    [fetchCurrency.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchCurrency.fulfilled]: (state, action) => {
      // console.log(action.payload)
      state.currency = action.payload
      state.status = 'succeeded';



      // state.products = action.payload;
      // state.filteredPro = action.payload;
    },
    [fetchCurrency.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  }
});

export const { setProduct, filterProducts, increment, applyFilter, resetFilter } = productSlice.actions
export default productSlice.reducer