import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import '../../../App.css'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListItems from './DashboardComponents/ListItems';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import RoutesNavigation from '../RoutesNavigation';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import BottomTabs from '../../Components/Navbar/BottomTabs';


const drawerWidth = 72;


//CURRENT DAT AND D


function DashboardContent() {
  const cartItems = useSelector((state) => state.CartReducer.cartItems)

  const [open, setOpen] = React.useState(true);


  //screen navigation in drawr
  const { t } = useTranslation()
  const navigate = useNavigate()



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
          AMIT BOOK DEPOT
          </Typography>
        </Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => navigate('/checkout')}
          sx={{
            position: 'absolute',
            right: 22,
            top:15
          }}
          disabled={cartItems.length<1}
        >
          <Badge badgeContent={cartItems.length!==null?cartItems.length:0} color="error">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </AppBar>


      <Drawer
        sx={{
          display: { xs: 'none', sm: 'flex' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            alignItems: 'center',
            marginTop: '4rem',
            zIndex: 9,
          },
        }}
        variant="permanent"
        anchor="left"
      >

        <List component="nav" sx={{ alignItems: 'center', position: 'fixed', zIndex: 9999, mt: 2 }}>
          <ListItems />
          {/* <Divider sx={{ my: 1 }} /> */}

        </List>
        <Divider />


      </Drawer>
      <BottomTabs />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              // ? theme.palette.grey[100]
              ? '#ffff'
              : theme.palette.grey[900],
          flexGrow: 1,
          // minHeight: '50vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />

        <Container maxWidth="xl" sx={{ p: 1,mb:{xs:5,sm:0},mt:{xs:0} }}>



          <RoutesNavigation />

        </Container>


      </Box>

    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}