import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';

function Brach({ filterState, setFilterState, reset }) {
  // const { theme, setTheme } = useFilterContext()

  const { products } = useSelector(state => state.PorductReducer)
  // const { categories } = useProductContext()
  // console.log(products)
  
  const uniqueBrances = 
  React.useMemo(() => {
    return    products ? products.reduce((acc, product) => {
      if (!acc.includes(product.branch)) {
        acc.push(product.branch);
      }
      return acc;
    }, []) : [];
  }, [products])

  



  const [value, setValue] = React.useState(uniqueBrances[0]);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    setValue([])
    setInputValue('')
  }, [reset])



  return (
    <FormControl sx={{ width: '100%', marginLeft: 1 }}>

      <MemoizedAutocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setFilterState({ ...filterState, branch: newValue })
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="branch"
        options={uniqueBrances}
        // sx={{ width: 300 }}
        getOptionLabel={(option) => typeof option === 'string' ? option : ''}
        isOptionEqualToValue={(option, value) => option === value}
        size='small'
        renderInput={(params) => <TextField {...params} label="Branch" />}
      />
    </FormControl>
  );
}
const MemoizedAutocomplete = React.memo(Autocomplete);

export default Brach;
