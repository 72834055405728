import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orderList: [],
    orderItems: [],
    orderCreds: {}
}

export const orderListSlice = createSlice({
    name: 'orderListReducer',
    initialState,
    reducers: {
        //ADD TO CART
        // placeOrder: (item, size, state) => {
        placeOrder: (state, action) => {

            state.orderList = [...state.orderList, action.payload]

        },

        //REMOVE FORM CART
        setOrderCreds: (state, action) => {
            state.orderCreds = action.payload;
        },
        addOrder: (state, action) => {
            state.orderList = [...state.orderList, action.payload]
            localStorage.setItem("orderList", JSON.stringify(state.orderList));

        },
        resetUserCreds: (state, action) => {
state.orderCreds={}
        },
        removeOrder: (state, action) => {
            state.orderList = state.orderList.filter((x) => x.isbn !== action.payload.isbn
            )

            localStorage.setItem("orderList", JSON.stringify(state.orderList.filter((x) => x.isbn !== action.payload.isb)))
        }, getOrderList: (state, action) => {
            const order = JSON.parse(localStorage.getItem('orderList'))
            if (order != null)
                state.orderList = order
        }





    },
})

// Action creators are generated for each case reducer function
export const { setOrderCreds, addOrder, resetUserCreds, removeOrder, getOrderList } = orderListSlice.actions

export default orderListSlice.reducer