import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './Redux/store'
import { Provider } from 'react-redux'
import tEn from './Translation/tEn.json'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { green, purple } from '@mui/material/colors';


i18n
  .use(initReactI18next)
  .init({

    resources: {
      en: {
        translation: tEn
      },
      // it: {
      //   translation: tIt
      // }
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <Provider store={store}>

      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
