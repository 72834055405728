import './App.css';
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import Cart from './source/Components/Cart/Cart';
import Dashboard from './source/Screens/Dashboard/Dashbaord';
import {
  HashRouter as Router, Route, Routes
} from "react-router-dom";
import Login from './source/Screens/AuthScreens/Login';
import ProtectedRoute from './Protected Route/ProtectedRoute';
import SignUp from './source/Screens/AuthScreens/Signup'
import ForgetPassword from './source/Screens/AuthScreens/ForgetPassword/ForgetPassword'
import { fetchProducts } from './Redux/productReducer';
import { getCartItems } from './Redux/cartReducer';
import { getOrderList } from './Redux/orderReducer';
import { fetchCurrency } from './Redux/productReducer';
import { useDispatch } from 'react-redux';
import { checkUser } from './Redux/authReducer';
import ErrorBoundary from './source/Components/ErrorBoundary/ErrorBoundary';
import LoaderToast from './source/Components/Toast/LoaderToast';

function App() {
  // console.log(process.env.REACT_APP_VARIABLE)
  const [mode, setMode] = React.useState('light');
  // console.log(process.env.REACT_APP_URL)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchProducts())
    dispatch(fetchCurrency())
    dispatch(getCartItems())
    dispatch(getOrderList())

  }, [dispatch])

  React.useEffect(() => {
    dispatch(checkUser())

  }, [])

  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          // palette values for light mode
          primary: {
            main: '#2196f3',
            medium: '#90caf9',
            light: '##F1ECEC'
          },
          // divider: amber[200],
          text: {
            primary: '#263238',
            secondary: '#757575',
            light: '#fff',
            white: '#fff'
          },
        }
        : {
          // palette values for dark mode
          primary: {
            main: '#757575',
            medium: '#b0bec5',
            light: "#e0e0e0",
            white: '#fff'
          },
          // divider: deepOrange[700],
          // background: {
          //   default: deepOrange[900],
          //   paper: deepOrange[900],
          // },
          text: {
            primary: '#e0e0e0',
            secondary: '#607d8b',
            light: '#263238'
          },
        }),
    },
  });

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);



  return (
    
    <ThemeProvider theme={theme} >
        <ErrorBoundary  >
         <LoaderToast/>
        <Router>

          <Routes>

            <Route index path='/*' exact element={<ProtectedRoute Component={Dashboard} />} />
            <Route path='/login' exact element={<Login />} />
            <Route path='/signUp' element={<SignUp />} />
            <Route path='/forgetPassword' element={<ForgetPassword />} />
          </Routes>
        </Router>

    </ErrorBoundary>


    
      </ThemeProvider >
  );
}

export default App;

