import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';


export default function CircularStatic({value}) {
  const [progress, setProgress] = React.useState(0);
  const {load } = useSelector((state) => state.PorductReducer)

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {/* <CircularProgress variant="determinate" {...props} /> */}
      <CircularProgress  />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" >
          {/* {`${Math.round(props.value)}%`} */}
          {props.value}%
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};


  React.useEffect(() => {
    // const timer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    // }, 800);
    // return () => {
    //   clearInterval(timer);
    // };
    if(load===1){
      setProgress(25)
    }else if(load===2){
      setProgress(50)
    }
    else if(load===3){
      setProgress(75)
    }
    else if(load===4){
      setProgress(100)
    }
    else {
      setProgress(0)
    }
  }, [load]);

  return <CircularProgressWithLabel value={progress} />;
}