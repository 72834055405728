import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import productReducer from './productReducer'
import cartSlice from './cartReducer'
import authSlice from './authReducer'
import orderListSlice from './orderReducer'
import settingSlice from './settingReducer'


const middleware = [...getDefaultMiddleware({
  serializableCheck: false
})];

export const store = configureStore({
  reducer: {
    CartReducer: cartSlice,
    PorductReducer: productReducer,
    Auth: authSlice,
    OrderListReducer: orderListSlice,
    SettingReducer:settingSlice,
    middleware

  },
})